import { Badge, Flex, Title, useMantineTheme } from "@mantine/core";
import { getCategory } from "api/fetch/category";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Feed } from "ui";

export default function Voted() {

    const { categoryId } = useParams<{ categoryId: string }>();
    const theme = useMantineTheme();

    const [data, setData] = useState<any>([]);
    useEffect(() => {
        getCategory(Number(categoryId)).then((res) => {
            setData(res);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    return <Feed args={{
        limit: 10,
        offset: 0,
        cat: `${categoryId}`
    }}>
        <Flex py={'lg'}>
            <Title order={1}>{data?.name}</Title>
            <Badge ml={5} color={theme.colorScheme === 'dark' ? 'white' : 'gray'} variant={'filled'} size={'sm'} py={0}>
                {data?.count}
            </Badge>
        </Flex>
    </Feed>

}