import locales from 'locale/locales.json';

export default function setCurrentLinkLocale(locale: string) {

    let link = window.location.pathname;
    const linkLocale = link.split('/')[1];

    const codes = locales.map((locale) => locale.code);
    
    if (codes.includes(linkLocale)) {
        link = link.replace(`/${linkLocale}`, '');
    }

    // const defaultLocale = locales.find((locale) => locale.default)?.code;
    // if (locale === defaultLocale)
    //     return link;

    return `/${locale}${link}`;

}