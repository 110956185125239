import { Anchor, Button, Card, Flex, Image, RingProgress, Skeleton, Text, useMantineTheme } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { IconChevronLeft, IconChevronRight, IconFlame, IconPoint } from "@tabler/icons";
import { getRSS } from "api/fetch/rss";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function RSSFeed() {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [posts, setPosts] = useState<{
        title: string;
        link: string;
        pubDate: string;
        enclosure: string;
    }[]>([]);
    const [page, setPage] = useState<number>(1);
    // const [seconds, setSeconds] = useState(0);
    const [counter, setCounter] = useState(0);
    const [progress, setProgress] = useState<number>(0);
    const perPage = useMemo(() => 5, []);

    const interval = useInterval(() => setCounter((s) => s + 10), 100);

    useEffect(() => {
        const progressDuration = 5; // seconds
        const secondsPassed = counter / 100;
        setProgress((secondsPassed / progressDuration) * 100);
        if (secondsPassed >= progressDuration) {
            if (page === Math.ceil(posts.length / perPage)) {
                setPage(1);
            } else {
                setPage(page + 1);
            }
        }
    }, [counter]);


    useEffect(() => {
        setProgress(0);
        setCounter(0);
    }, [page]);

    useEffect(() => {
        interval.start();
        return interval.stop;
    }, []);

    useEffect(() => {
        getRSS().then((res) => {
            setPosts(typeof res === typeof [] ? res : []);
        }).catch((err) => {
            console.log(err);
        });
    }, []);


    return (
        <Card withBorder p={'sm'}>
            <Flex direction={'row'} gap={5}>
                <IconFlame size={20} color={theme.colors.brand[6]} />
                <Text size={'sm'} weight={700} mb={'xs'} color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9]}>
                    {
                        posts.length ?
                            t('Latest Updates')
                            :
                            <Skeleton width={100} height={20} />
                    }
                </Text>
            </Flex>
            {
                posts.length ? (
                    <Flex
                        direction={'column'}
                        gap={'md'}
                        sx={{
                            opacity: 1,
                            transition: '.2s ease',
                            '&:hover': {
                                opacity: 1
                            }
                        }}
                    >
                        <Flex direction={'row'} justify={'space-between'} align={'center'} gap={5}>
                            <Button
                                variant={'light'}
                                size={'xs'}
                                onClick={() => {
                                    setPage(page - 1);
                                }}
                                px={5}
                                disabled={page === 1}
                            >
                                {
                                    theme.dir === 'rtl' ?
                                        <IconChevronRight size={20} /> :
                                        <IconChevronLeft size={20} />
                                }
                            </Button>
                            {
                                Array(Math.ceil(posts.length / perPage)).fill(0).map((item, index) => (
                                    <RingProgress
                                        key={index}
                                        size={25}
                                        thickness={4}
                                        onClick={() => setPage(index + 1)}
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                        sections={
                                            index + 1 === page ? [
                                                { value: progress, color: theme.colors.blue[6] },
                                                { value: 100 - progress, color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2] }
                                            ] : [
                                                { value: 100, color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2] }
                                            ]
                                        }
                                    />
                                ))
                            }

                            <Button
                                variant={'light'}
                                size={'xs'}
                                px={5}
                                onClick={() => {
                                    setPage(page + 1);
                                }}
                                disabled={page === Math.ceil(posts.length / perPage)}
                            >
                                {
                                    theme.dir === 'rtl' ?
                                        <IconChevronLeft size={20} /> :
                                        <IconChevronRight size={20} />
                                }
                            </Button>
                        </Flex>
                        <Flex direction={'column'} gap={0}>
                            {
                                posts.map((item, index) => (
                                    <Anchor
                                        href={item.link}
                                        target="_blank"
                                        key={index}
                                        sx={{
                                            textDecoration: 'none',
                                            color: theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.gray[7],
                                            transition: 'color 200ms ease, max-height 500ms ease, padding 500ms ease',
                                            // msTransitionTimingFunction: 'step-start',
                                            overflow: 'hidden',
                                            '&:hover': {
                                                color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.gray[8],
                                            },
                                            ...(index >= page * perPage - perPage && index < page * perPage ? {
                                                maxHeight: 100,
                                                padding: '5px 0',
                                            } : {
                                                maxHeight: 0,
                                                pading: 0,
                                            })
                                        }}
                                        onMouseEnter={() => interval.stop()}
                                        onMouseLeave={() => interval.start()}
                                    >
                                        <Flex key={index} direction={'row'} gap={'xs'} align={'center'}>
                                            <Image src={item.enclosure} width={50} height={50} radius={'md'} />
                                            <Flex direction={'column'} gap={0}>
                                                <Text fw={500} size={'sm'}>
                                                    {item.title}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Anchor>
                                ))
                                // posts.slice(page * perPage - perPage, page * perPage).map((item, index) => (
                                //     <Anchor
                                //         href={item.link}
                                //         target="_blank"
                                //         key={index}
                                //         sx={{
                                //             textDecoration: 'none',
                                //             color: theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.gray[7],
                                //             transition: '.2s ease',
                                //             '&:hover': {
                                //                 color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.gray[8],
                                //             }
                                //         }}
                                //         onMouseEnter={() => interval.stop()}
                                //         onMouseLeave={() => interval.start()}
                                //     >
                                //         <Flex key={index} direction={'row'} gap={'xs'} align={'center'}>
                                //             <Image src={item.enclosure} width={50} height={50} radius={'md'} />
                                //             <Flex direction={'column'} gap={0}>
                                //                 <Text fw={500} size={'sm'}>
                                //                     {item.title}
                                //                 </Text>
                                //             </Flex>
                                //         </Flex>
                                //     </Anchor>
                                // ))
                            }
                        </Flex>
                    </Flex>
                ) : (
                    Array(perPage).fill(0).map((item, index) => (
                        <Link to={'#'} key={index} style={{ textDecoration: 'none', color: theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.gray[7] }}>
                            <Flex key={index} direction={'row'} gap={5}>
                                <IconPoint size={20} color={theme.colors.brand[6]} />
                                <Skeleton width={'100%'} height={15} />
                            </Flex>
                        </Link>
                    ))
                )
            }
        </Card>
    )

}