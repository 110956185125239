import { Flex, Loader, LoadingOverlay, Text, useMantineTheme } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Opinion, Votes } from "types";

export default function VoteRow({
    opinion,
    onVote,
    loading,
}: {
    opinion: Opinion,
    onVote?: (vote: number) => void,
    loading?: boolean,
}) {

    const [results, setResults] = useState<Votes>(opinion.votes);
    const [total, setTotal] = useState<number>(opinion.votes.total);
    const [data, setData] = useState<number[]>(opinion.votes.types);
    const [userVote, setUserVote] = useState<number | null>(opinion.user_vote);

    useEffect(() => {
        setResults(opinion.votes);
        setTotal(opinion.votes.total);
        setData(opinion.votes.types);
        setUserVote(opinion.user_vote);
    }, [opinion]);

    const theme = useMantineTheme();

    const mergedData = [...Array(5)].map((_, index) => {
        let newData = {
            value: data[index],
            percent: total ? (data[index] / total) * 100 : 0,
            bg: theme.colors.vote[index],
            color: theme.colors.voteInverse[index],
        }
        return newData;
    });

    const { t } = useTranslation();

    const labels = [
        t('Strongly Disagree'),
        t('Disagree'),
        t('Neutral'),
        t('Agree'),
        t('Strongly Agree'),
    ];

    return (
        <Flex
            sx={theme => ({
                borderRadius: 10,
                height: 40,
                marginLeft: theme.spacing.md,
                marginRight: theme.spacing.md,
                marginTop: userVote != null ? theme.spacing.lg : 0,
                transition: 'all 0.3s ease',
                position: 'relative',
                padding: 2,
            })}
            direction={'row-reverse'}
        >
            {
                loading && (
                    <LoadingOverlay visible={true} zIndex={1000} overlayBlur={2}
                        loaderProps={{
                            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
                            size: 16,
                        }}
                    />
                )
            }
            {
                mergedData.map((item, index) => (
                    <Flex key={index} bg={item.bg} justify={'center'} align={'center'}
                        sx={(theme: any) => ({
                            transition: 'all 0.3s ease',
                            ...(userVote == null && {
                                cursor: 'pointer',
                                opacity: 1,
                                '&:hover': {
                                    opacity: 0.9,
                                },
                            } as any),
                            flexGrow: Math.max(Math.round(item.percent), 1),
                            padding: '0 5px',
                            ...(index == 0 && {
                                borderRadius: theme.direction === 'ltr' ? '5px 0 0 5px' : '0 5px 5px 0',
                            } as any),
                            ...(index == 4 && {
                                borderRadius: theme.direction === 'ltr' ? '0 5px 5px 0' : '5px 0 0 5px',
                            } as any),
                        })}
                        onClick={() => !loading && onVote && onVote(index)}
                    >
                        <Text
                            sx={(theme) => ({
                                fontSize: theme.fontSizes.xs,
                                fontWeight: 500,
                                position: 'relative',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                ...(!loading && userVote == index && {
                                    '&:after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: -24,
                                        left: "calc(50% - 10px)",
                                        width: 24,
                                        height: 24,
                                        mask: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 384 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z'/%3E%3C/svg%3E")`,
                                        '-webkit-mask': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 384 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z'/%3E%3C/svg%3E")`,
                                        backgroundColor: item.bg,
                                    },
                                } as any),
                            })}
                            color={item.color}
                            style={{ lineHeight: 1 }}
                            align={'center'}
                        >
                            {
                                userVote != null ? (
                                    <>{Number(item.percent || 0).toFixed(1)}%</>
                                ) : (
                                    <>{labels[index]}</>
                                )
                            }
                        </Text>
                    </Flex>
                ))
            }
        </Flex>
    )

    // const width = window.innerWidth;
    // const theme = useMantineTheme();

    // // merge defaultData with data
    // const mergedData = [...Array(5)].map((_, index) => ({
    //     label: data[index] ? data[index].label : null,
    //     value: data[index] ? data[index].value : null,
    //     bg: theme.colors.vote[index],
    //     color: theme.colors.voteInverse[index],
    // }));


    // return (
    //     <Flex
    //         style={{
    //             borderRadius: 10,
    //             overflow: 'hidden',
    //             height: 40,
    //             marginLeft: width < 400 ? 0 : theme.spacing.md,
    //             marginRight: width < 400 ? 0 : theme.spacing.md,
    //         }}
    //     >
    //         {
    //             mergedData.map((item, index) => (
    //                 <Flex key={index} bg={item.bg} justify={'center'} align={'center'}
    //                     sx={{
    //                         cursor: 'pointer',
    //                         transition: 'all 0.2s ease',
    //                         opacity: 1,
    //                         '&:hover': {
    //                             opacity: 0.9,
    //                         },
    //                         flexGrow: 1,
    //                         padding: '0 5px',
    //                     }}
    //                 >
    //                     <Text
    //                         sx={(theme) => ({
    //                             fontSize: theme.fontSizes.xs,
    //                         })}
    //                         color={item.color}
    //                         style={{ lineHeight: 1 }}
    //                         align={'center'}
    //                     >
    //                         {item.label}
    //                     </Text>
    //                 </Flex>
    //             ))
    //         }
    //     </Flex>
    // )
}