import { Alert, Button, Grid, LoadingOverlay, NumberInput, PasswordInput, SegmentedControl, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle, IconArrowBackUp, IconAsterisk, IconAt, IconMoodEmpty, IconMoodSmileBeam } from '@tabler/icons';
import { newAccount } from 'api/fetch/account';
import { PasswordStrength } from 'components';
import { CenterFocus, WarperLoginForm } from 'layout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { makeLink } from 'utils';
import parseError from 'utils/parseError';

export default function Login() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [stage, setStage] = useState<'register' | 'verify'>('register');
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const formRegister = useForm({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            gender: '',
            password: '',
            passwordConfirm: '',
            passwordStrength: 0,
        },
        validate: {
            firstName: (value) => (value.length < 3 ? t("Invalid name") : null),
            lastName: (value) => (value.length < 3 ? t("Invalid name") : null),
            email: (value) => (value.length < 5 || !value.match(/^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? t("Invalid email address") : null),
            gender: (value) => ["male", "female"].includes(value) ? null : t("Please select your gender"),
            password: (value) => (value.length < 8 ? t("Invalid password") : null),
        }
    });

    const formVerify = useForm({
        initialValues: {
            code: '',
            email: '',
        },
    });

    return (
        <CenterFocus>
            <WarperLoginForm>
                {
                    stage === 'register' && (
                        <form
                            onSubmit={formRegister.onSubmit((values) => {

                                if (values.password !== values.passwordConfirm) {
                                    formRegister.setErrors({
                                        passwordConfirm: t("Passwords do not match"),
                                    });
                                    return;
                                }

                                if (values.passwordStrength < .5) {
                                    formRegister.setErrors({
                                        password: t("Password is too weak"),
                                    });
                                    return;
                                }

                                setLoading(true);
                                setErrorMessage("");
                                newAccount({
                                    name: values.firstName + ' ' + values.lastName,
                                    email: values.email,
                                    password: values.password,
                                    gender: values.gender,
                                }).then((response) => {
                                    formVerify.setFieldValue('email', values.email);
                                    setStage('verify');
                                }).catch((error) => {
                                    const { msg } = parseError(error);
                                    setErrorMessage(msg);
                                    setLoading(false);
                                }).finally(() => {
                                    setLoading(false);
                                });

                            })}
                        >
                            <Grid
                                style={{
                                    maxWidth: 300,
                                    margin: '0 auto',
                                }}
                            >
                                <LoadingOverlay visible={loading} overlayBlur={2} />
                                <Grid.Col span={12}>
                                    {
                                        errorMessage !== "" && (
                                            <Alert icon={<IconAlertCircle size="1rem" />} title="Error!" color="red" radius="sm">
                                                {errorMessage}
                                            </Alert>
                                        )
                                    }
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Button
                                        onClick={() => navigate(makeLink('/login'))}
                                        leftIcon={<IconArrowBackUp size={20} />}
                                        variant="subtle"
                                        fullWidth
                                    >
                                        {t("Back")}
                                    </Button>
                                </Grid.Col>
                                <Grid.Col span={6}>
                                    <TextInput
                                        placeholder={t("First name") as string}
                                        icon={<IconMoodSmileBeam size={20} />}
                                        {...formRegister.getInputProps('firstName')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={6}>
                                    <TextInput
                                        placeholder={t("Last name") as string}
                                        icon={<IconMoodEmpty size={20} />}
                                        {...formRegister.getInputProps('lastName')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Select
                                        data={[
                                            { label: t('Male') as string, value: 'male' },
                                            { label: t('Female') as string, value: 'female' },
                                        ]}
                                        {...formRegister.getInputProps("gender")}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <TextInput
                                        placeholder={t("Email") as string}
                                        icon={<IconAt size={20} />}
                                        {...formRegister.getInputProps('email')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <PasswordInput
                                        placeholder={t("Password") as string}
                                        icon={<IconAsterisk size={20} />}
                                        {...formRegister.getInputProps('password')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <PasswordInput
                                        placeholder={t("Confirm Password") as string}
                                        icon={<IconAsterisk size={20} />}
                                        {...formRegister.getInputProps('passwordConfirm')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <PasswordStrength
                                        value={formRegister.values.password}
                                        onChange={(value) => {
                                            formRegister.setFieldValue('passwordStrength', value);
                                        }}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="gradient"
                                    >
                                        {t("Register")}
                                    </Button>
                                </Grid.Col>
                            </Grid>
                        </form>
                    )
                }

                {
                    stage === 'verify' && (
                        <form
                            onSubmit={formVerify.onSubmit((values) => {
                                console.log(values);
                            })}
                        >
                            <Grid
                                style={{
                                    maxWidth: 300,
                                    margin: '0 auto',
                                }}
                            >
                                <LoadingOverlay visible={false} overlayBlur={2} />
                                <Grid.Col span={12}>
                                    <Text
                                        align="center"
                                    >
                                        {t("We have sent a verification code to your email address:") + ' '}
                                        <strong>{formRegister.values.email}</strong>
                                    </Text>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Button
                                        fullWidth
                                        variant="gradient"
                                        onClick={() => {
                                            navigate(makeLink('/login'));
                                        }}
                                    >
                                        {t("Sign In")}
                                    </Button>
                                </Grid.Col>
                            </Grid>
                        </form>
                    )
                }
            </WarperLoginForm>
        </CenterFocus>
    );

}