import { Card, Flex, Text, Box, Anchor, Image } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconBrandAndroid, IconBrandApple, IconBrandGooglePlay } from "@tabler/icons";
import { useTranslation } from "react-i18next";

export default function DownloadCard() {

    const { t } = useTranslation();

    return (
        <Card withBorder padding={'xs'}>
            <Flex gap={'xs'} align={'center'} direction={'column'}>

                {
                    [
                        {
                            icon: IconBrandGooglePlay,
                            title: t("Get it on"),
                            subtitle: 'Google Play',
                            link: 'https://play.google.com/store/apps/details?id=com.mediaZan.qarar',
                        },
                        {
                            icon: IconBrandApple,
                            title: t("Download on the"),
                            subtitle: 'App Store',
                            link: 'https://apps.apple.com/us/app/qarar/id6467507044',
                        },
                        {
                            icon: IconBrandAndroid,
                            title: t("Direct download as"),
                            subtitle: 'APK',
                            link: 'https://qarar.me/wp-content/themes/naxos-child/qarar.apk',
                        },
                    ].map((item, index) => (
                        <DownloadItem key={index} {...item} />
                    ))
                }

            </Flex>
        </Card>
    )

}

const DownloadItem = ({ icon, title, subtitle, link }: { icon: any, title: string, subtitle: string, link: string }) => {

    const Icon = icon;
    const { hovered, ref } = useHover();

    return (
        <Anchor href={link} target={'_blank'} sx={{ width: '100%' }}>
            <Card withBorder w={'100%'} p={'xs'}
                ref={ref}
                sx={theme => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
                })}
            >
                <Flex direction={'row'} gap={'xs'} w={'100%'} align={'center'} dir="ltr">
                    <Flex align={'center'} dir="ltr" justify={'center'}>
                        <Box
                            sx={{
                                width: hovered ? 45 : 0,
                                overflow: 'hidden',
                                transition: 'width 0.3s ease',
                            }}
                        >
                            <Image src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${link}`} alt={subtitle} />
                        </Box>
                        <Box
                            sx={{
                                width: hovered ? 0 : 45,
                                overflow: 'hidden',
                                transition: 'width 0.3s ease',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Icon size={40} />
                        </Box>
                    </Flex>
                    {
                        // hovered ? (
                        //     <Box w={45}>
                        //         <Image src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${link}`} alt={subtitle} />
                        //     </Box>
                        // ) : (
                        //     <Icon size={40} />
                        // )
                    }
                    <Box>
                        <Text transform="uppercase" weight={500} size={'xs'}>
                            {title}
                        </Text>
                        <Text weight={700} size={'lg'}>
                            {subtitle}
                        </Text>
                    </Box>
                </Flex>
            </Card>
        </Anchor>
    )

}