import { Button, Grid, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { updateAccount } from "api/fetch/account";
import { useQueryAccount } from "api/hook/user";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parseError from "utils/parseError";

export default function ProfileModal() {

    const [loading, setLoading] = useState(false);

    const {
        data: user,
        refetch
    } = useQueryAccount();

    const form = useForm({
        initialValues: {},
    });

    useEffect(() => {
        if (user) {
            setLoading(false);
            user.name && form.setFieldValue('name', user.name);
            user.email && form.setFieldValue('email', user.email);
            user.birth_date && form.setFieldValue('birth_date', dayjs(user.birth_date).toDate());
            user.phone_number && form.setFieldValue('phone_number', user.phone_number);
            user.education && form.setFieldValue('education', user.education);
            user.gender && form.setFieldValue('gender', user.gender);
            user.work && form.setFieldValue('work', user.work);
        }
    }, [user]);

    const { t } = useTranslation();

    return (
        <form
            onSubmit={
                form.onSubmit((values: any) => {
                    setLoading(true);
                    updateAccount(values).then(() => {
                        refetch();
                        modals.closeAll();
                    }).catch((err) => {
                        alert(parseError(err).msg);
                    }).finally(() => {
                        setLoading(false);
                    });
                })
            }
        >
            <Grid gutter={'md'} p={'md'}>
                <Grid.Col xs={12}>
                    <TextInput
                        disabled={loading}
                        label={t("Name")}
                        placeholder={t("Your name") as string}
                        {...form.getInputProps('name')}
                    />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                    <DateInput
                        disabled={loading}
                        label={t("Birth date")}
                        placeholder={t("Select date") as string}
                        {...form.getInputProps('birth_date')}
                    />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                    <TextInput
                        disabled={loading}
                        label={t("Phone")}
                        placeholder={t("Your phone number") as string}
                        {...form.getInputProps('phone_number')}
                    />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                    <Select
                        disabled={loading}
                        label={t("Education")}
                        placeholder={t("Education") as string}
                        data={[{ value: "primary_school", label: t("Primary School") as string },
                        { value: "high_school", label: t("High School") as string },
                        { value: "university", label: t("University") as string },
                        { value: "diploma", label: t("Diploma") as string },
                        { value: "master", label: t("Master") as string },
                        { value: "doctorate", label: t("Doctorate") as string },
                        { value: "other", label: t("Other") as string },
                        ]}
                        {...form.getInputProps('education')}
                    />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                    <Select
                        disabled={loading}
                        label={t("Gender")}
                        placeholder={t("Gender") as string}
                        data={[
                            { value: "male", label: t("Male") as string },
                            { value: "female", label: t("Female") as string },
                        ]}
                        {...form.getInputProps('gender')}
                    />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                    <Select
                        disabled={loading}
                        label={t("Work")}
                        placeholder={t("Work") as string}
                        data={[
                            { value: "student", label: t("Student") as string },
                            { value: "employee", label: t("Employee") as string },
                            { value: "unemployed", label: t("Unemployed") as string },
                            { value: "freelancer", label: t("Freelancer") as string },
                            { value: "retired", label: t("Retired") as string },
                            { value: "business_owner", label: t("Business Owner") as string },
                            { value: "other", label: t("Other") as string },
                        ]}
                        {...form.getInputProps('work')}
                    />
                </Grid.Col>
                <Grid.Col xs={12}>
                    <Button type="submit" color="blue" loading={loading}>
                        {t("Update")}
                    </Button>
                </Grid.Col>
            </Grid>
        </form>
    )

}