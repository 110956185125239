import { useParams } from "react-router-dom";
import { Feed } from "ui";

export default function Search() {

    const { search } = useParams();

    return <Feed args={{
        limit: 10,
        offset: 0,
        search,
    }} />

}