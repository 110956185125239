// vendors
import { Box, Container } from '@mantine/core';
import * as React from 'react';

// utils
import { Header } from 'ui';

export default function CenterFocus({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <>
            <Header />
            <Box className='layout-children'>
                <Container size={'lg'}>
                    {children}
                </Container>
            </Box>
        </>
    );
}