import dayjs from 'dayjs';
import { apiWithInterceptor, apiWithInterceptorFormData } from './';
import axios, { CancelTokenSource } from 'axios';

let cancelTokens: { [key: string]: CancelTokenSource } = {};

export const getAccount = async (): Promise<any> => {
    cancelTokens.getAccount && cancelTokens.getAccount.cancel();
    cancelTokens.getAccount = axios.CancelToken.source();
    return apiWithInterceptor.get(`/account`, {})
};

export const newAccount = async (data: any): Promise<any> => {
    cancelTokens.newAccount && cancelTokens.newAccount.cancel();
    cancelTokens.newAccount = axios.CancelToken.source();
    return apiWithInterceptor.post(`/account`, data, {})
};

export const updateAccount = async (data: {
    name?: string,
    email?: string,
    birth_date?: string,
    phone_number?: string,
    education?: string,
    gender?: string,
    work?: string,
}) : Promise<any> => apiWithInterceptor.patch("/account", {
    ...data,
    ...(data.birth_date && { birth_date: dayjs(data.birth_date).format("YYYY-MM-DD HH:mm:ss") })
});

export const updateAccountPicture = async (data: {
    picture: any,
}) : Promise<any> => apiWithInterceptorFormData.post("/account/picture", data);