import {
    Home,
    Trending,
    Voted,
    Search,

    Category,

    SingleOpinion,

    Profile,

    Login,
    LoginByEmail,
    LoginForgotPassword,
    RegisterByEmail,
    SinglePage,
} from "pages";
import { Navigate } from "react-router-dom";

export default [
    {
        path: '',
        element: <Home />
    },
    {
        path: '/trending',
        element: <Trending />
    },
    {
        path: '/voted',
        element: <Voted />
    },
    {
        path: '/search/:search',
        element: <Search />
    },
    {
        path: '/category/:categoryId',
        element: <Category />
    },
    {
        path: '/:lang/opinion',
        children: [
            {
                path: '',
                element: <Navigate to={'/'}/>
            },
            {
                path: ':opinionId',
                element: <SingleOpinion />
            }
        ]
    },
    {
        path: '/page',
        children: [
            {
                path: '',
                element: <Navigate to={'/'}/>
            },
            {
                path: ':pageId',
                element: <SinglePage />
            }
        ]
    },
    {
        path: '/login',
        children: [
            {
                path: '',
                element: <Login />
            },
            {
                path: 'email',
                element: <LoginByEmail />
            },
            {
                path: 'forgot',
                element: <LoginForgotPassword />
            },
        ]
    },
    {
        path: '/profile',
        element: <Profile />
    },
    {
        path: '/register',
        element: <RegisterByEmail />
    },
    {
        path: '/about',
        element: <Login />
    },
    {
        path: '/privacy',
        element: <Login />
    }
]