import { faker } from "@faker-js/faker";
import { ActionIcon, Anchor, Avatar, Badge, Box, Button, Card, Chip, Flex, Group, LoadingOverlay, Menu, Skeleton, Text, Textarea, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconCornerDownRight, IconDots, IconEdit, IconSend, IconThumbUp, IconTrash } from "@tabler/icons";
import { createComment, deleteComment, editComment, getComments, likeComment, unlikeComment } from "api/fetch/comments";
import { useQueryAccount } from "api/hook/user";
import { ProfileButton } from "components";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Comment as CommentType } from "types";
import parseError from "utils/parseError";


export default function OpinionComments({
    loading = false,
}: {
    loading?: boolean,
}) {

    const { t } = useTranslation();
    const [comments, setComments] = useState<CommentType[]>([]);
    const { opinionId } = useParams();
    useEffect(() => {
        getComments(Number(opinionId)).then((data) => {
            setComments([
                ...data.comments.map((comment: CommentType) => ({
                    ...comment,
                    children: comment.children ? comment.children.reverse() : [],
                })),
            ]);
        }).catch((error) => {
            console.log(error);
        });
    }, []);
    const {
        data,
    } = useQueryAccount();

    const [activeReplies, setActiveReplies] = useState<number[]>([]);
    const toggleReply = useCallback((id: number) => {
        if (activeReplies.includes(id)) {
            setActiveReplies((activeReplies) => {
                return activeReplies.filter((item) => item !== id);
            });
        } else {
            setActiveReplies((activeReplies) => {
                return [
                    ...activeReplies,
                    id,
                ]
            });
        }
    }, [activeReplies]);

    return (
        <Flex direction={'column'} gap={'xs'}>
            <Card withBorder sx={{ overflow: 'visible' }}>
                <Flex direction={'column'} gap={'xs'}>
                    <Text weight={700} size={'sm'}>
                        {
                            loading ? (
                                <Skeleton width={'30%'} height={20} />
                            ) : (
                                t('Comments')
                            )
                        }
                    </Text>
                    {comments.map((comment) => (
                        <Flex direction={'column'} gap={'xs'} >
                            <Comment
                                key={comment.id}
                                comment={comment}
                                onLike={() => {
                                    likeComment(comment.id).then((data) => {
                                        setComments((comments) => {
                                            return comments.map((item) => {
                                                if (item.id === comment.id) {
                                                    return {
                                                        ...item,
                                                        ...data,
                                                        children: item.children,
                                                    }
                                                }
                                                return item;
                                            });
                                        });
                                    }).catch((error) => {

                                    });
                                }}
                                onUnlike={() => {
                                    unlikeComment(comment.id).then((data) => {
                                        setComments((comments) => {
                                            return comments.map((item) => {
                                                if (item.id === comment.id) {
                                                    return {
                                                        ...item,
                                                        ...data,
                                                        children: item.children,
                                                    }
                                                }
                                                return item;
                                            });
                                        });
                                    }).catch((error) => {

                                    });
                                }}
                                onReply={() => toggleReply(comment.id)}
                                hasChildren={comment?.children?.length > 0 || activeReplies.includes(comment.id)}
                                onDelete={() => {
                                    const currentComments = comments;
                                    modals.openConfirmModal({
                                        title: t('Delete comment'),
                                        children: (
                                            <Text size="sm">
                                                {t('Are you sure you want to delete this comment?')}
                                            </Text>
                                        ),
                                        labels: { confirm: t('Delete'), cancel: t('Cancel') },
                                        onConfirm: () => {
                                            deleteComment(comment.id).then((data) => {
                                                setComments((comments) => {
                                                    return comments.filter((item) => item.id !== comment.id);
                                                });
                                            }).catch((error) => {
                                                setComments(currentComments);
                                                const { msg } = parseError(error);
                                                alert(msg);
                                            });
                                        },
                                    });
                                }}
                                onEdit={() => modals.open({
                                    title: t('Edit comment'),
                                    children: (
                                        <CommentBox
                                            opinionId={Number(opinionId)}
                                            commentId={comment.id}
                                            parent={comment.parent}
                                            content={comment.content}
                                            type={comment.type as any}
                                            update={true}
                                            onComment={(comment) => {
                                                setComments((comments) => {
                                                    return comments.map((item) => {
                                                        if (item.id === comment.id) {
                                                            return {
                                                                ...item,
                                                                ...comment,
                                                                children: item.children,
                                                            }
                                                        }
                                                        return item;
                                                    });
                                                });
                                                modals.closeAll();
                                            }} />
                                    ),
                                })}
                            />
                            {
                                (comment.children || []).map((reply) => (
                                    <Reply
                                        key={reply.id}
                                        comment={reply}
                                        onDelete={() => {
                                            const currentComments = comments;
                                            modals.openConfirmModal({
                                                title: t('Delete comment'),
                                                children: (
                                                    <Text size="sm">
                                                        {t('Are you sure you want to delete this comment?')}
                                                    </Text>
                                                ),
                                                labels: { confirm: t('Delete'), cancel: t('Cancel') },
                                                onConfirm: () => {
                                                    deleteComment(reply.id).then((data) => {
                                                        setComments((comments) => {
                                                            return comments.map((item) => {
                                                                if (item.id === reply.parent) {
                                                                    return {
                                                                        ...item,
                                                                        children: item.children.filter((child) => child.id !== reply.id),
                                                                    }
                                                                }
                                                                return item;
                                                            });
                                                        });
                                                    }).catch((error) => {
                                                        setComments(currentComments);
                                                        const { msg } = parseError(error);
                                                        alert(msg);
                                                    });
                                                },
                                            });
                                        }}
                                        onEdit={() => modals.open({
                                            title: t('Edit comment'),
                                            children: (
                                                <CommentBox
                                                    opinionId={Number(opinionId)}
                                                    commentId={reply.id}
                                                    parent={reply.parent}
                                                    content={reply.content}
                                                    update={true}
                                                    onComment={(comment) => {
                                                        setComments((comments) => {
                                                            return comments.map((item) => {
                                                                if (item.id === comment.parent) {
                                                                    return {
                                                                        ...item,
                                                                        children: item.children.map((child) => {
                                                                            if (child.id === comment.id) {
                                                                                return {
                                                                                    ...child,
                                                                                    ...comment,
                                                                                }
                                                                            }
                                                                            return child;
                                                                        }),
                                                                    }
                                                                }
                                                                return item;
                                                            });
                                                        });
                                                        modals.closeAll();
                                                    }} />
                                            ),
                                        })}
                                    />
                                ))
                            }
                            {
                                activeReplies.includes(comment.id) && (
                                    <ReplyWarper sx={{
                                        padding: 0,
                                    }}>
                                        <CommentBox opinionId={Number(opinionId)} parent={comment.id} onComment={(comment) => {
                                            setComments((comments) => {
                                                return comments.map((item) => {
                                                    if (item.id === comment.parent) {
                                                        return {
                                                            ...item,
                                                            children: [
                                                                ...item.children,
                                                                comment,
                                                            ]
                                                        }
                                                    }
                                                    return item;
                                                });
                                            });
                                        }} />
                                    </ReplyWarper>
                                )
                            }
                        </Flex>
                    ))}
                </Flex>
            </Card>
            {
                !loading && (
                    data ? (
                        <CommentBox
                            opinionId={Number(opinionId)}
                            onComment={(comment) => {
                                setComments((comments) => {
                                    return [
                                        ...comments,
                                        comment,
                                    ]
                                });
                            }}
                        />
                    ) : (
                        <ProfileButton />
                    )
                )
            }
        </Flex>
    )

}

const Comment = ({
    comment,
    onLike,
    onUnlike,
    onReply,
    onDelete,
    onEdit,
    hasChildren = false,
}: {
    comment: CommentType,
    onLike?: (comment: CommentType) => void,
    onUnlike?: (comment: CommentType) => void,
    onReply?: () => void,
    onDelete?: () => void,
    onEdit?: () => void,
    hasChildren?: boolean,
}) => {

    const [commentData, setCommentData] = useState<CommentType>(comment);
    const [showOrginal, setShowOrginal] = useState<boolean>(false);
    useEffect(() => {
        setCommentData(comment);
    }, [comment]);
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const { data } = useQueryAccount();

    return (
        <Flex direction={'column'} gap={'xs'} p={'xs'}
            bg={theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}
            sx={theme => ({ borderRadius: `${theme.radius.md} ${theme.radius.md} ${theme.radius.md} ${hasChildren ? '0' : theme.radius.md}` })}
        >
            <Flex justify={'space-between'} align={'center'}>
                <Flex gap={'xs'} align={'center'}>
                    <Avatar
                        size={'sm'}
                        radius={'xl'}
                        bg={'dark.8'}
                    >
                        {commentData?.user?.name[0]}
                    </Avatar>
                    <Text weight={700} size={'xs'}>
                        {commentData?.user?.name}
                    </Text>
                    <Badge
                        variant={'filled'}
                        sx={{
                            backgroundColor: commentData?.type === 'with' ? theme.colors.vote[4] : commentData?.type === 'against' ? theme.colors.vote[0] : theme.white,
                            color: commentData?.type === 'with' ? theme.colors.voteInverse[4] : commentData?.type === 'against' ? theme.colors.voteInverse[0] : theme.black,
                        }}
                    >
                        {commentData?.type && t(commentData?.type)}
                    </Badge>
                </Flex>
                {
                    commentData?.mine && (
                        <Menu shadow="md" width={200}>
                            <Menu.Target>
                                <ActionIcon
                                    variant="default"
                                >
                                    <IconDots size="1rem" />
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item icon={<IconEdit size="1rem" />}
                                    onClick={() => onEdit && onEdit()}
                                >
                                    {t('Edit')}
                                </Menu.Item>
                                <Menu.Item color="red" icon={<IconTrash size="1rem" />}
                                    onClick={() => onDelete && onDelete()}
                                >
                                    {t('Delete')}
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    )
                }
            </Flex>
            <Text size={'xs'}>
                {/* {commentData?.content} */}
                {
                    showOrginal ? commentData?.orginal_content : commentData?.content
                }
                {
                    commentData.translated && (
                        <Text size={'xs'} color={'gray.6'} sx={{ fontStyle: 'italic' }}>
                            {t('Translated from')}: {commentData.orginal_language}
                            <Anchor
                                px={'xs'}
                                sx={{
                                    textDecoration: 'underline',
                                    userSelect: 'none',
                                }}
                                onClick={() => setShowOrginal(!showOrginal)}
                            >
                                {
                                    showOrginal ? t('Hide orginal') : t('Show orginal')
                                }
                            </Anchor>
                        </Text>
                    )
                }
                <Flex gap={5} align={'center'} justify={'flex-end'}>
                    {
                        Boolean(commentData?.history?.length) && (
                            <Text
                                size={'xs'}
                                color={'gray.6'}
                                sx={{ fontStyle: 'italic', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                    modals.open({
                                        title: t('Revisions'),
                                        children: (
                                            <Flex direction={'column'} gap={'xs'} p={'xs'}>
                                                {
                                                    commentData?.history.map((item: any, index) => (
                                                        <Box
                                                            sx={{
                                                                borderLeft: `2px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
                                                                paddingLeft: theme.spacing.xs,
                                                                marginLeft: theme.spacing.xs,
                                                            }}
                                                        >
                                                            <Text key={index} size={'xs'}>
                                                                {item.content}
                                                            </Text>
                                                        </Box>
                                                    ))
                                                }
                                            </Flex>
                                        ),
                                    });
                                }}
                            >
                                {t('Edited')}
                            </Text>
                        )
                    }
                    {
                        data && (
                            <ActionIcon
                                variant="default"
                                onClick={() => onReply && onReply()}
                            >
                                <IconCornerDownRight size="1rem" />
                            </ActionIcon>
                        )
                    }
                    {
                        commentData?.liked ? (
                            <ActionIcon variant="default"
                                onClick={() => {
                                    if (!data) return;
                                    setCommentData({
                                        ...commentData,
                                        liked: false,
                                        likes: commentData.likes - 1,
                                    });
                                    onUnlike && onUnlike(commentData);
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M13 3a3 3 0 0 1 2.995 2.824l.005 .176v4h2a3 3 0 0 1 2.98 2.65l.015 .174l.005 .176l-.02 .196l-1.006 5.032c-.381 1.626 -1.502 2.796 -2.81 2.78l-.164 -.008h-8a1 1 0 0 1 -.993 -.883l-.007 -.117l.001 -9.536a1 1 0 0 1 .5 -.865a2.998 2.998 0 0 0 1.492 -2.397l.007 -.202v-1a3 3 0 0 1 3 -3z" stroke-width="0" fill="currentColor" /><path d="M5 10a1 1 0 0 1 .993 .883l.007 .117v9a1 1 0 0 1 -.883 .993l-.117 .007h-1a2 2 0 0 1 -1.995 -1.85l-.005 -.15v-7a2 2 0 0 1 1.85 -1.995l.15 -.005h1z" stroke-width="0" fill="currentColor" /></svg>
                            </ActionIcon>
                        ) : (
                            <ActionIcon variant="default"
                                onClick={() => {
                                    if (!data) return;
                                    setCommentData({
                                        ...commentData,
                                        liked: true,
                                        likes: commentData.likes + 1,
                                    });
                                    onLike && onLike(commentData);
                                }}
                            >
                                <IconThumbUp size="1rem" />
                            </ActionIcon>
                        )
                    }
                    <Text size={'xs'} px={3}>{commentData?.likes}</Text>
                </Flex>
            </Text>
        </Flex>
    )

};

const ReplyWarper = (props: { children: React.ReactNode, sx?: any }) => {

    const theme = useMantineTheme();

    return (
        <Flex direction={'column'} gap={'xs'} p={'xs'} ml={'lg'}
            bg={theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}
            sx={theme => ({
                borderRadius: theme.radius.md,
                position: 'relative',
                zIndex: 1,
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: -55,
                    left: -20,
                    width: 20,
                    height: 'calc(100% + 40px)',
                    // backgroundColor: theme.colors.dark[4],
                    borderRadius: `0 0 0 ${theme.radius.xl}`,
                    border: `2px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
                    borderRight: 'none',
                    borderTop: 'none',
                    zIndex: 0
                },
                ...(props.sx || {}),
            })}
        >
            {props.children}
        </Flex>
    )

};

const Reply = ({
    comment,
    onDelete,
    onEdit,
}: {
    comment?: CommentType,
    onDelete?: () => void,
    onEdit?: () => void,
}) => {

    const { t } = useTranslation();

    return (
        <ReplyWarper sx={{ zIndex: 2 }}>
            <Flex gap={'xs'} align={'center'} justify={'space-between'}>
                <Flex gap={'xs'} align={'center'}>
                    <Avatar
                        size={'sm'}
                        radius={'xl'}
                        bg={'dark.8'}
                    >
                        {comment?.user?.name[0]}
                    </Avatar>
                    <Text weight={700} size={'xs'}>
                        {comment?.user?.name}
                    </Text>
                </Flex>
                {
                    comment?.mine && (
                        <Menu shadow="md" width={200} position="top">
                            <Menu.Target>
                                <ActionIcon
                                    variant="default"
                                >
                                    <IconDots size="1rem" />
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item icon={<IconEdit size="1rem" />}
                                    onClick={() => onEdit && onEdit()}
                                >
                                    {t('Edit')}
                                </Menu.Item>
                                <Menu.Item color="red" icon={<IconTrash size="1rem" />}
                                    onClick={() => onDelete && onDelete()}
                                >
                                    {t('Delete')}
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    )
                }
            </Flex>
            <Text size={'xs'}>
                {comment?.content}
            </Text>
            {
                Boolean(comment?.history?.length) && (
                    <Text
                        size={'xs'}
                        color={'gray.6'}
                        sx={{ fontStyle: 'italic', textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => {
                            modals.open({
                                title: t('Revisions'),
                                children: (
                                    <Flex direction={'column'} gap={'xs'} p={'xs'}>
                                        {
                                            comment?.history.map((item: any, index) => (
                                                <Box
                                                    sx={(theme: any) => ({
                                                        borderLeft: `2px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
                                                        paddingLeft: theme.spacing.xs,
                                                        marginLeft: theme.spacing.xs,
                                                    })}
                                                >
                                                    <Text key={index} size={'xs'}>
                                                        {item.content}
                                                    </Text>
                                                </Box>
                                            ))
                                        }
                                    </Flex>
                                ),
                            });
                        }}
                    >
                        {t('Edited')}
                    </Text>
                )
            }
        </ReplyWarper>
    )

};


const CommentBox = ({
    onComment = () => { },
    opinionId,
    commentId,
    update = false,
    parent = null,
    content = "",
    type = 'commentary',
}: {
    onComment?: (comment: CommentType) => void,
    opinionId: number,
    commentId?: number,
    update?: boolean,
    parent?: number | null,
    content?: string,
    type?: 'commentary' | 'with' | 'against',
}) => {

    const theme = useMantineTheme();
    const { t } = useTranslation();
    const form = useForm({
        initialValues: {
            content: content,
            type: type as string,
            parent: parent,
        },
        validate: {
            content: (value) => value.trim().length > 0 ? null : t('Please type something'),
        },
    });
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <form
            onSubmit={form.onSubmit((values) => {
                setLoading(true);
                if (update) {
                    editComment(commentId ?? 0, values as any).then((data) => {
                        onComment(data);
                        form.reset();
                    }).catch((error) => {
                        const { msg } = parseError(error);
                        form.setFieldError('content', msg);
                    }).finally(() => {
                        setLoading(false);
                    });
                } else {
                    createComment(opinionId, values as any).then((data) => {
                        onComment(data);
                        form.reset();
                    }).catch((error) => {
                        const { msg } = parseError(error);
                        form.setFieldError('content', msg);
                    }).finally(() => {
                        setLoading(false);
                    });
                }
            })}
        >
            <Card withBorder
                p={0}
                sx={{
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[0],
                    borderColor: (form.values.type === 'commentary' ? (theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]) : form.values.type === 'with' ? (theme.colors.vote[3]) : (theme.colors.vote[1])) + '!important',
                    boxShadow: form.values.type === 'commentary' ? 'unset' : '0 0 50px 0 ' + (form.values.type === 'commentary' ? (theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]) : form.values.type === 'with' ? (theme.colors.vote[3]) : (theme.colors.vote[1])) + '33!important',
                    position: 'relative',
                    marginTop: '-100px',
                    transform: 'scale(0)',
                    '@keyframes comment-box': {
                        '0%': {
                            marginTop: '-100px',
                            transform: 'scale(0)',
                        },
                        '100%': {
                            marginTop: '0px',
                            transform: 'scale(1)',
                        }
                    },
                    animation: 'comment-box 200ms ease-in-out forwards',
                }}
            >
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Flex direction={'column'} gap={'xs'} p={'xs'}>
                    <Textarea
                        placeholder={t('Write a comment') as string}
                        variant="filled"
                        autosize
                        sx={{
                            '& textarea': {
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
                            },
                            '& textarea:focus': {
                                borderColor: '#0000!important',
                            }
                        }}
                        {...form.getInputProps('content')}
                    />
                    <Box>
                        <Flex justify={'space-between'} align={'center'}>
                            {
                                parent == null || parent == 0 ? (
                                    <Chip.Group
                                        defaultValue={'commentary'}
                                        {...form.getInputProps('type')}
                                    >
                                        <Group sx={{ gap: theme.spacing.xs }}>
                                            {
                                                [
                                                    {
                                                        value: 'commentary',
                                                        label: t('cm_mixed'),
                                                    },
                                                    {
                                                        value: 'with',
                                                        label: t('cm_for'),
                                                    },
                                                    {
                                                        value: 'against',
                                                        label: t('cm_against'),
                                                    }
                                                ].map((item, index) => (
                                                    <Chip
                                                        key={index}
                                                        variant="outline"
                                                        value={item.value}
                                                        sx={{
                                                            '& label': {
                                                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1],
                                                                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
                                                            }
                                                        }}
                                                    >
                                                        {item.label}
                                                    </Chip>

                                                ))
                                            }
                                        </Group>
                                    </Chip.Group>
                                ) : (
                                    <Box />
                                )
                            }
                            <Button
                                variant={theme.colorScheme === 'dark' ? 'white' : 'filled'}
                                color={theme.colorScheme === 'dark' ? 'gray' : 'dark'}
                                size="xs"
                                type="submit"
                                leftIcon={<IconSend size={16} />}
                            >
                                {t('Send')}
                            </Button>
                        </Flex>
                    </Box>
                </Flex>
            </Card>
        </form>
    )

}