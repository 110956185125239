
export default function useSeed (): number {

    let seed = Math.floor(Math.random() * 10000);
    let seedDate = new Date();

    const storageSeed = localStorage.getItem("seed") || 0;
    const storageSeedDate = localStorage.getItem("seedDate") || 0;

    if (Math.abs(new Date(storageSeedDate).getTime() - new Date().getTime()) > 60 * 60 * 1000) {
        localStorage.setItem("seed", seed.toString());
        localStorage.setItem("seedDate", seedDate.toString());
    } else {
        seed = Number(storageSeed);
        seedDate = new Date(storageSeedDate);
    }

    return Number(seed);

}