import { Button, Flex, LoadingOverlay, NumberInput, PasswordInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useViewportSize } from '@mantine/hooks';
import { IconArrowBackUp, IconAsterisk, IconAt } from '@tabler/icons';
import { getPasswordRestCode, resetPassword, validatePasswordRestCode } from 'api/fetch/auth';
import { PasswordStrength } from 'components';
import { CenterFocus, WarperLoginForm } from 'layout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { makeLink } from 'utils';
import parseError from 'utils/parseError';

export default function LoginForgotPassword() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { width } = useViewportSize();
    const [btnSize, setBtnSize] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xl'>('sm');
    const [stage, setStage] = useState<'email' | 'code' | 'password'>('email');
    const [code, setCode] = useState<string>('');

    useEffect(() => {
        if (width < 350) {
            setBtnSize('xs');
        } else {
            setBtnSize('sm');
        }
    }, [width]);

    const formEmail = useForm({
        initialValues: {
            email: '',
        },
        validate: {
            email: (value) => (value.length < 5 || !value.match(/^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? t("Invalid email address") : null),
        }
    });

    const formCode = useForm({
        initialValues: {
            code: '',
            email: '',
        },
        validate: {
            code: (value) => (String(value).length !== 6 ? t("Invalid code") : null),
        }
    });

    const formPassword = useForm({
        initialValues: {
            email: '',
            code: '',
            password: '',
            passwordConfirm: '',
            passwordStrength: 0,
        },
    });

    return (
        <CenterFocus>
            <WarperLoginForm>
                <LoadingOverlay visible={loading} overlayBlur={2} />
                {stage === 'email' && (
                    <form
                        onSubmit={formEmail.onSubmit(values => {
                            setLoading(true);
                            getPasswordRestCode(values.email).then((res) => {
                                formCode.setFieldValue('email', values.email);
                                setStage('code');
                            }).catch((err) => {
                                const { msg } = parseError(err);
                                formEmail.setErrors({
                                    email: msg,
                                });
                            }).finally(() => {
                                setLoading(false);
                            });
                        })}
                    >
                        <Flex
                            direction="column"
                            gap="xs"
                            pb={'lg'}
                            style={{
                                maxWidth: 300,
                                margin: '0 auto',
                            }}
                        >
                            <LoadingOverlay visible={false} overlayBlur={2} />
                            <Button
                                onClick={() => navigate(makeLink('/login/email'))}
                                leftIcon={<IconArrowBackUp size={20} />}
                                variant="subtle"
                                size={btnSize}
                            >
                                {t("Back")}
                            </Button>
                            <TextInput
                                placeholder={t('Email') as string}
                                label={t('Email') as string}
                                variant="filled"
                                size="sm"
                                icon={<IconAt size={20} />}
                                {...formEmail.getInputProps('email')}
                            />
                            <Button
                                variant="gradient"
                                type="submit"
                                size={btnSize}
                                mt={'sx'}
                            >
                                {t('Send Code')}
                            </Button>
                        </Flex>
                    </form>
                )}


                {stage === 'code' && (
                    <form
                        onSubmit={formCode.onSubmit(values => {
                            setLoading(true);
                            validatePasswordRestCode(values.email, values.code).then((res) => {
                                formPassword.setFieldValue('code', values.code);
                                formPassword.setFieldValue('email', values.email);
                                setStage('password');
                            }).catch((err) => {
                                const { msg } = parseError(err);
                                formCode.setErrors({
                                    code: msg,
                                });
                            }).finally(() => {
                                setLoading(false);
                            });
                        })}
                    >
                        <Flex
                            direction="column"
                            gap="xs"
                            pb={'lg'}
                            style={{
                                maxWidth: 300,
                                margin: '0 auto',
                            }}
                        >
                            <LoadingOverlay visible={false} overlayBlur={2} />
                            <Button
                                onClick={() => navigate(makeLink('/login/email'))}
                                leftIcon={<IconArrowBackUp size={20} />}
                                variant="subtle"
                                size={btnSize}
                            >
                                {t("Back")}
                            </Button>
                            <NumberInput
                                placeholder={t('Code') as string}
                                label={t('Code') as string}
                                variant="filled"
                                size="sm"
                                icon={<IconAsterisk size={20} />}
                                {...formCode.getInputProps('code')}
                            />
                            <Button
                                variant="gradient"
                                type="submit"
                                size={btnSize}
                                mt={'sx'}
                            >
                                {t('Verify Code')}
                            </Button>
                        </Flex>
                    </form>
                )}

                {stage === 'password' && (
                    <form
                        onSubmit={formPassword.onSubmit(values => {

                            if (values.password !== values.passwordConfirm) {
                                formPassword.setErrors({
                                    passwordConfirm: t("Passwords do not match"),
                                });
                                return;
                            }

                            if (values.passwordStrength < .5) {
                                formPassword.setErrors({
                                    password: t("Password is too weak"),
                                });
                                return;
                            }

                            setLoading(true);
                            
                            resetPassword(values.email, values.code, values.password).then((res) => {
                                navigate(makeLink('/login'));
                            }).catch((err) => {
                                const { msg } = parseError(err);
                                formPassword.setErrors({
                                    password: msg,
                                });
                            }).finally(() => {
                                setLoading(false);
                            });

                        })}
                    >
                        <Flex
                            direction="column"
                            gap="xs"
                            pb={'lg'}
                            style={{
                                maxWidth: 300,
                                margin: '0 auto',
                            }}
                        >
                            <LoadingOverlay visible={false} overlayBlur={2} />
                            <Button
                                onClick={() => navigate(makeLink('/login/email'))}
                                leftIcon={<IconArrowBackUp size={20} />}
                                variant="subtle"
                                size={btnSize}
                            >
                                {t("Back")}
                            </Button>
                            <PasswordInput
                                placeholder={t('Password') as string}
                                label={t('Password') as string}
                                variant="filled"
                                size="sm"
                                icon={<IconAsterisk size={20} />}
                                {...formPassword.getInputProps('password')}
                            />
                            <PasswordInput
                                placeholder={t('Confirm Password') as string}
                                label={t('Confirm Password') as string}
                                variant="filled"
                                size="sm"
                                icon={<IconAsterisk size={20} />}
                                {...formPassword.getInputProps('passwordConfirm')}
                            />
                            <PasswordStrength
                                value={formPassword.values.password}
                                onChange={(value) => {
                                    formPassword.setFieldValue('passwordStrength', value);
                                }}
                            />
                            <Button
                                variant="gradient"
                                type="submit"
                                size={btnSize}
                                mt={'sx'}
                            >
                                {t('Change Password')}
                            </Button>
                        </Flex>
                    </form>
                )}

            </WarperLoginForm>
        </CenterFocus>
    );

}