import { Switch, useMantineTheme, rem, Card, Flex, Text, ActionIcon } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconSun, IconMoonStars } from '@tabler/icons';
import { useTranslation } from "react-i18next";


export default function ThemeCard() {

    const theme = useMantineTheme();
    const { t } = useTranslation();
    // const [colorScheme] = useLocalStorage({ key: 'color-scheme', defaultValue: 'dark' });
    const [colorScheme, setColorScheme] = useLocalStorage({ key: 'color-scheme', defaultValue: 'dark' });

    const sunIcon = (
        <IconSun
            style={{ width: rem(16), height: rem(16) }}
            stroke={2.5}
            color={theme.colors.yellow[4]}
        />
    );

    const moonIcon = (
        <IconMoonStars
            style={{ width: rem(16), height: rem(16) }}
            stroke={2.5}
            color={theme.colors.blue[6]}
        />
    );

    return (
        <Card withBorder>
            <Flex
                direction={'row'}
                gap={'xs'}
                align={'center'}
            >
                <ActionIcon
                    variant='outline'
                    onClick={() => setColorScheme(colorScheme === 'dark' ? 'light' : 'dark')}
                    sx={{
                        borderColor: theme.colors.gray[theme.colorScheme === 'dark' ? 7 : 3],
                    }}
                >
                    { colorScheme === 'dark' ? sunIcon : moonIcon }
                </ActionIcon>
                <Text size={'sm'}>
                    {colorScheme === 'dark' ? t('Light mode') : t('Dark mode')}
                </Text>
            </Flex>
        </Card>
    )

}