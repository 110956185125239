import { Card, Flex, Image, Title, TypographyStylesProvider } from "@mantine/core";
import { getOpinion } from "api/fetch/opinions";
import { WithSideBar } from "layout";
import { useEffect, useState } from "react";
import { Opinion } from "types";
import { OpinionAnalysis, OpinionCard, OpinionComments, OpinionShare } from "ui";
import { useParams } from 'react-router-dom';
import { getPage } from "api/fetch/pages";


export default function SinglePage() {

    const [page, setPage] = useState<any>(null);
    const { pageId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        if (!pageId) return;
        setLoading(true);
        getPage(Number(pageId)).then((data) => {
            setPage(data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <WithSideBar>
            <Flex gap={'xs'} direction={'column'}>
                {
                    page?.featured_image && (
                        <Card withBorder>
                            <Image src={page?.featured_image?.sizes?.large} alt={page?.title} />
                        </Card>
                    )
                }
                <Card withBorder mb={300}>
                    <Title order={1}>{page?.title}</Title>
                    <TypographyStylesProvider>
                        <div
                            dangerouslySetInnerHTML={{ __html: page?.content_rendered }}
                        />
                    </TypographyStylesProvider>
                </Card>
            </Flex>
        </WithSideBar>
    )

}