import { useTranslation } from 'react-i18next';

export default function useCurrentLang (): string {

    const { i18n } = useTranslation();
    let locale = localStorage.getItem("locale") || i18n.language;
    if (locale === 'ckb' || locale === 'krd') {
        locale = 'ku';
    }

    return locale;

}