import { Button, Flex, LoadingOverlay, PasswordInput, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useViewportSize } from '@mantine/hooks';
import { IconArrowBackUp, IconAsterisk, IconAt } from '@tabler/icons';
import { CenterFocus, WarperLoginForm } from 'layout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { makeLink } from 'utils';

export default function LoginByEmail() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { width } = useViewportSize();
    const [btnSize, setBtnSize] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xl'>('sm');

    useEffect(() => {
        if (width < 350) {
            setBtnSize('xs');
        } else {
            setBtnSize('sm');
        }
    }, [width]);

    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },
        validate: {
            email: (value) => (value.length < 5 || !value.match(/^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? t("Invalid email address") : null),
            password: (value) => (value.length < 8 ? t("Invalid password") : null),
        }
    });

    return (
        <CenterFocus>
            <WarperLoginForm>
                <form
                    onSubmit={form.onSubmit(values => {
                        console.log(values);
                    })}
                >
                    <Flex
                        direction="column"
                        gap="xs"
                        pb={'lg'}
                        style={{
                            maxWidth: 300,
                            margin: '0 auto',
                        }}
                    >
                        <LoadingOverlay visible={false} overlayBlur={2} />
                        <Button
                            onClick={() => navigate(makeLink('/login'))}
                            leftIcon={<IconArrowBackUp size={20} />}
                            variant="subtle"
                            size={btnSize}
                        >
                            {t("Back")}
                        </Button>
                        <TextInput
                            placeholder={t('Email') as string}
                            label={t('Email') as string}
                            variant="filled"
                            size="sm"
                            icon={<IconAt size={20} />}
                            {...form.getInputProps('email')}
                        />
                        <PasswordInput
                            placeholder={t('Password') as string}
                            label={t('Password') as string}
                            variant="filled"
                            size="sm"
                            icon={<IconAsterisk size={20} />}
                            {...form.getInputProps('password')}
                        />
                        <Link to={makeLink('/login/forgot')}>
                            <Text align="right" size="xs">
                                {t('Forgot password?')}
                            </Text>
                        </Link>
                        <Button
                            variant="gradient"
                            type="submit"
                            size={btnSize}
                            mt={'sx'}
                        >
                            {t('Sign In')}
                        </Button>
                        <Text align="center" size="xs">
                            {t('Or')}
                        </Text>
                        <Button
                            variant="white"
                            size={btnSize}
                            onClick={() => navigate(makeLink('/register'))}
                        >
                            {t('Create Account')}
                        </Button>
                    </Flex>
                </form>
            </WarperLoginForm>
        </CenterFocus>
    );

}