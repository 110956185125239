import { Button, Card, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAt, IconDeviceMobile, IconMail } from "@tabler/icons";
import { useTranslation } from "react-i18next";

export default function UserLoginInformation() {

    const { t } = useTranslation();
    const form = useForm({
        initialValues: {
            username: '',
            email: '',
            phone: '',
        }
    });

    return (
        <Card withBorder>
            <Flex direction={'column'} gap={'xs'} align={'center'} justify={'center'}>
                <IconAt size={50} />
                <Text weight={700} size={'sm'}>
                    {t('Account Information')}
                </Text>
                <form style={{ width: '100%' }}>
                    <Flex direction={'column'} gap={'xs'}>
                        <TextInput
                            placeholder={t('Username') as string}
                            variant={'filled'}
                            size={'xs'}
                            icon={<IconAt size={20} />}
                            {...form.getInputProps('username')}
                        />
                        <TextInput
                            placeholder={t('Email') as string}
                            variant={'filled'}
                            size={'xs'}
                            icon={<IconMail size={20} />}
                            {...form.getInputProps('email')}
                        />
                        <TextInput
                            placeholder={t('Phone') as string}
                            variant={'filled'}
                            size={'xs'}
                            icon={<IconDeviceMobile size={20} />}
                            {...form.getInputProps('phone')}
                        />
                        {
                            form.isDirty() && (
                                <Button
                                    variant={'white'}
                                    size="xs"
                                    fullWidth
                                >
                                    {t('Update')}
                                </Button>
                            )
                        }
                    </Flex>
                </form>
            </Flex>
        </Card>
    )

}