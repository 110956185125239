import { useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import { default as locales } from 'locale/locales.json';
import LayoutWarper from 'LayoutWarper';
import {default as routesList} from 'data/routes';

export default function Router() {

    const localesList = useMemo(() => locales.filter(locale => locale.default !== true), []);
    const routes = useMemo(() => {

        let allRoutes = routesList;

        // localesList.forEach(locale => {
        //     allRoutes = [
        //         ...allRoutes,
        //         ...routesList.map(route => ({
        //             ...route,
        //             path: `/${locale.code}${route.path}`
        //         }))
        //     ];
        // });

        return allRoutes;

    }, []);

    return useRoutes([
        ...routes,
        ...routes.map(route => ({
            ...route,
            path: `/:locale${route.path}`,
        })),
        ,
        {
            path: '*',
            element: "404"
        },
    ]);

}


