// vendors
import { Box, Container, Flex, Grid, MediaQuery } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';

// utils
import { FooterBox, Header, Trending, UserCard, ThemeCard, DownloadCard, RSSFeed } from 'ui';
import { SearchBox } from 'components';

const sideBarStyle = {
    position: 'relative',
}

const sideBarInnerStyle = {
    position: 'sticky',
    // top: 'calc(-1 * 100% + 100vh)',
}

export default function WithSideBar({
    children,
    mobileMenu = <DefaultMoileMenu />,
    leftSidebar = <DefaultLeftSidebar />,
    rightSidebar = <DefaultRightSidebar />,
}: {
    children: React.ReactNode;
    mobileMenu?: React.ReactNode;
    leftSidebar?: React.ReactNode;
    rightSidebar?: React.ReactNode;
}) {

    const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
    const sidebarRef_1 = useRef<HTMLDivElement>(null);
    const sidebarRef_2 = useRef<HTMLDivElement>(null);

    useEffect(() => {

        let lastScrollTop = 0;
        const scrollHandler = () => {
            const st = window.pageYOffset || document.documentElement.scrollTop;
            // if (st > lastScrollTop) {
            //     scrollDirection !== 'down' && setScrollDirection('down');
            // } else {
            //     scrollDirection !== 'up' && setScrollDirection('up');
            // }
            // lastScrollTop = st <= 0 ? 0 : st;

            // on scroll position change check the direction and set the state
            if (st - lastScrollTop > 0) {
                setScrollDirection('down');
                // console.log('down');
            } else {
                setScrollDirection('up');
                // console.log('up');
            }
            // set the new last scroll top
            // console.log(st-lastScrollTop > 0);
            lastScrollTop = st <= 0 ? 0 : st;


        }

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        }

    }, []);

    // useEffect(() => {
    //     console.log('scrollDirection', scrollDirection);
    // }, [scrollDirection]);

    return (
        <Box>
            <Header
                mobileMenu={mobileMenu}
            />
            <Box className='layout-children'
                sx={theme => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
                })}
            >
                <Container size={'lg'}>
                    <Grid pt={'xs'}>
                        <MediaQuery smallerThan='md' styles={{ display: 'none' }}>
                            <Grid.Col xs={0} sm={3} style={sideBarStyle as any}>
                                <Box
                                    ref={sidebarRef_1}
                                    style={{
                                        ...sideBarInnerStyle as any,
                                        top: (sidebarRef_1.current?.clientHeight ? sidebarRef_1.current?.clientHeight : 0) > window.innerHeight
                                            ? window.innerHeight - (sidebarRef_1.current?.clientHeight ? sidebarRef_1.current?.clientHeight : 0)
                                            : 60
                                        // top: scrollDirection === 'down'
                                        //     ? window.innerHeight - (sidebarRef_1.current?.clientHeight? sidebarRef_1.current?.clientHeight : 0)
                                        //     : - document.body.scrollHeight - (sidebarRef_1.current?.clientHeight? sidebarRef_1.current?.clientHeight : 0) - (window.pageYOffset || document.documentElement.scrollTop)
                                    }}
                                    pb={'lg'}
                                >
                                    {leftSidebar}
                                </Box>
                            </Grid.Col>
                        </MediaQuery>
                        <Grid.Col xs={12} md={6}>
                            {children}
                        </Grid.Col>
                        <MediaQuery smallerThan='md' styles={{ display: 'none' }}>
                            <Grid.Col xs={0} sm={3} style={sideBarStyle as any}>
                                <Box
                                    ref={sidebarRef_2}
                                    style={{
                                        ...sideBarInnerStyle as any,
                                        top: (sidebarRef_2.current?.clientHeight ? sidebarRef_2.current?.clientHeight : 0) > window.innerHeight
                                            ? window.innerHeight - (sidebarRef_2.current?.clientHeight ? sidebarRef_2.current?.clientHeight : 0)
                                            : 60
                                    }}
                                    pb={'lg'}
                                >
                                    {rightSidebar}
                                </Box>
                            </Grid.Col>
                        </MediaQuery>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

const DefaultRightSidebar = () => (
    <Flex direction={'column'} gap={'xs'}>
        {/* <WebsiteSummary /> */}
        {/* <TopContributers /> */}
        <RSSFeed />
    </Flex>
)

const DefaultLeftSidebar = () => (
    <Flex direction={'column'} gap={'xs'}>
        <ThemeCard />
        <UserCard />
        <DownloadCard />
        <FooterBox />
    </Flex>
)

const DefaultMoileMenu = () => (
    <Flex direction={'column'} gap={'xs'}>
        <SearchBox />
        <ThemeCard />
        <UserCard />
        <DownloadCard />
        <FooterBox />
    </Flex>
)