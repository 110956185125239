import { Button, Card } from "@mantine/core";

export default function UserLogout() {

    return (
        <Card withBorder>
            <Button variant={'light'} fullWidth color={'red'}>
                Logout
            </Button>
        </Card>
    )

}