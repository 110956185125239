import { default as localesList } from '../locale/locales.json';

export default function useLinkLocale (): string {

    const locale = window.location.pathname.split('/')[1];
    const codes = localesList.map((locale) => locale.code);

    if (locale && codes.includes(locale)) {
        return locale;
    }

    const defaultLocale = localesList.find((locale) => locale.default)?.code;

    return defaultLocale || 'ar';

}