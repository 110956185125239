import { useSeed } from "hooks";
import { Feed } from "ui";

export default function Home() {

    const seed = useSeed();

    return <Feed args={{
        limit: 10,
        offset: 0,
        orderby: 'rand',
        seed, 
    }} />

}