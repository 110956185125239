// vedndor
import { Avatar, Button, Text } from '@mantine/core';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { loginGoogle } from 'api/fetch/auth';
import { useQueryAccount } from 'api/hook/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeLink } from 'utils';

export default function ProfileButton() {

    const { t } = useTranslation();
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    const {
        data,
        isLoading,
        failureCount,
        failureReason,
    } = useQueryAccount();

    useEffect(() => {
        if (data) {
            setLoggedIn(true);
        }
    }, [data]);

    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
            const token = credentialResponse.credential;
            if (!token) {
                alert(t('حدث خطأ ما أثناء تسجيل الدخول بحساب جوجل'));
                return;
            }
            loginGoogle({ token }).then((res: any) => {
                localStorage.setItem('token', res.token)
                window.location.reload()
            }).catch((err: any) => {
                alert(err.message)
            }).finally(() => {
            });
        },
        onError: () => {
            alert(t('حدث خطأ ما أثناء تسجيل الدخول بحساب جوجل'));
        },
        disabled: failureCount === 0,
    });

    if (loggedIn) {
        return (
            <Button
                bg={'gray.8'}
                size="xs"
                mx={5}
                px={2}
            >
                <Text px={5}>
                    {data?.name?.slice(0, 15)}
                </Text>
                <Avatar
                    size={'sm'}
                    bg={'brand'}
                    src={data?.profile_picture?.medium?.url}
                >
                    {data?.name?.slice(0, 1)}
                </Avatar>
            </Button>
        );
    }

    return (
        <Link to={makeLink('/login')} style={{
            marginLeft: 5,
        }}>
            <Button
                variant="filled"
                size="xs"
                w={'100%'}
            >
                {t('Sign In')}
            </Button>
        </Link>
    );

}

