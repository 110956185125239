// vendors
import { Box, Burger, Button, Container, Drawer, Flex, Grid, Group, NavLink, ScrollArea, useMantineTheme } from '@mantine/core';
import { IconHome, IconMoodSuprised, IconCircleCheck } from '@tabler/icons';
import { Link, useNavigate } from 'react-router-dom';
import { ProfileButton, SearchBox } from 'components';
import { BrandLogo } from 'components';
import { makeLink } from 'utils';
import { useToggle, useViewportSize } from '@mantine/hooks';

export default function Header(
    {
        mobileMenu,
    } : {
        mobileMenu?: React.ReactNode;
    }
) {

    const { width } = useViewportSize();

    if (width > 992) return <DesktopHeader />
    return (
        <>
            <MobileHeader mobileMenu={mobileMenu} />
            <MobileNav />
        </>
    )

}

const navItems = [
    {
        icon: IconHome,
        link: '/',
    },
    {
        icon: IconMoodSuprised,
        link: makeLink('/trending'),
    },
    {
        icon: IconCircleCheck,
        link: makeLink('/voted'),
    },
]

const DesktopHeader = () => {

    const navigate = useNavigate();
    const theme = useMantineTheme();

    return (
        <header className='header' style={{
            backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[7]}CD` : `${theme.colors.gray[2]}CD`,
        }}>
            <Container size={'lg'}>
                <Grid
                    style={{
                        height: 50,
                        direction: 'ltr',
                    }}
                >
                    <Grid.Col xs={0} sm={3}>
                        <SearchBox />
                    </Grid.Col>
                    <Grid.Col xs={8} sm={6}>
                        <Group style={{
                            justifyContent: 'center',
                        }}>
                            {navItems.map((item, index) => (
                                <Button
                                    key={index}
                                    variant="subtle"
                                    onClick={() => navigate(item.link)}
                                >
                                    {<item.icon size={25} />}
                                </Button>
                            ))}
                        </Group>
                    </Grid.Col>
                    <Grid.Col style={{ height: 'inherit' }} xs={4} sm={3}>
                        <Flex align={'center'} justify={'space-between'} style={{ height: '100%', width: '100%' }}>
                            <ProfileButton />
                            <Link to={makeLink('/')}>
                                <BrandLogo style={{ width: '6vw', minWidth: '60px', maxWidth: '80px', padding: '0 1rem' }} />
                            </Link>
                        </Flex>
                    </Grid.Col>
                </Grid>
            </Container>
        </header>
    )

}

const MobileHeader = ( {
    mobileMenu,
} : {
    mobileMenu?: React.ReactNode;
} ) => {

    const [menuStatus, toggleMenuStatus] = useToggle([false, true]);
    const theme = useMantineTheme();

    return (
        <header
            className='header'
            style={{
                zIndex: 202,
                backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[7]}CD` : `${theme.colors.gray[2]}CD`,
            }}
        >
            <Container size={'lg'}>
                <Grid
                    style={{
                        height: 50,
                        direction: 'ltr',
                    }}
                >
                    <Grid.Col style={{ height: 'inherit' }} xs={12}>
                        <Flex align={'center'} justify={'space-between'} style={{ height: '100%', width: '100%' }}>
                            <Burger
                                opened={menuStatus}
                                onClick={() => toggleMenuStatus()}
                                style={{
                                    height: '100%',
                                }}
                            />
                            <Link to={'/'}>
                                <BrandLogo style={{ height: '100%', padding: '10px 0' }} />
                            </Link>
                        </Flex>
                    </Grid.Col>
                </Grid>
            </Container>
            <Drawer
                opened={menuStatus}
                onClose={toggleMenuStatus}
                size={'100%'}
                withCloseButton={false}
                scrollAreaComponent={ScrollArea.Autosize}
                transitionProps={{
                    transition: 'skew-up',
                }}
            >
                <Box
                    pt={50}
                >
                    {mobileMenu}
                </Box>
            </Drawer>
        </header>
    )

}

const MobileNav = () => {

    const navigate = useNavigate();
    const theme = useMantineTheme();

    return (
        <Group
            style={{
                justifyContent: 'center',
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                zIndex: 100,
                padding: '.3rem 0',
                backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[7]}CD` : `${theme.colors.gray[2]}CD`,
                backdropFilter: 'blur(7px)',
            }}
            // bg={'dark.7'}
        >
            {navItems.map((item, index) => (
                <Button
                    key={index}
                    variant="subtle"
                    onClick={() => navigate(item.link)}
                >
                    {<item.icon size={25} />}
                </Button>
            ))}
        </Group>
    )

}