import { useMantineTheme } from '@mantine/core';
import LogoWhite from 'assets/logo-white.svg';
import LogoBlack from 'assets/logo-black.svg';

export default function BrandLogo(props: any) {

    const theme = useMantineTheme();
    
    let color = theme.colorScheme === 'dark' ? 'light' : 'dark';

    if (props.color && props.color === 'dark') color = 'dark';
    if (props.color && props.color === 'light') color = 'light';

    return <img {...props} src={color === 'dark' ? LogoBlack : LogoWhite} alt="logo" />

}