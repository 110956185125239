import { Flex } from "@mantine/core";
import { getOpinion } from "api/fetch/opinions";
import { WithSideBar } from "layout";
import { useEffect, useState } from "react";
import { Opinion } from "types";
import { OpinionAnalysis, OpinionCard, OpinionComments, OpinionShare } from "ui";
import { useParams } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import { Helmet } from "react-helmet-async";

export default function SingleOpinion() {

    const [opinion, setOpinion] = useState<Opinion | null>(null);
    const { opinionId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [meta, setMeta] = useState<any>({});

    useEffect(() => {
        if (!opinionId) return;
        setLoading(true);
        getOpinion(Number(opinionId)).then((data) => {
            setOpinion(data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        setMeta({
            ...{
                title: opinion?.title,
                description: opinion?.title,
                canonical: window.location.href,
                // set image
                meta: {
                    property: {
                        'og:image': opinion?.featured_image?.sizes?.large,
                    }
                }
            }
        });
    }, [opinion]);

    return (
        <WithSideBar>
            <Flex gap={'xs'} direction={'column'} pb={300}>
                {/* <DocumentMeta {...meta} /> */}
                <Helmet>
                    { /* Standard metadata tags */}
                    <title>{meta.title}</title>
                    <meta name="description" content={opinion?.title} />
                    <link rel="canonical" href={window.location.href} />
                    {/* Open Graph metadata */}
                    <meta property="og:title" content={opinion?.title} />
                    <meta property="og:description" content={opinion?.title} />
                    <meta property="og:image" content={opinion?.featured_image?.sizes?.large} />
                    <meta property="og:url" content={window.location.href} />
                </Helmet>
                <OpinionCard opinion={opinion || undefined} loading={loading} />
                {/* <OpinionAnalysis /> */}
                <OpinionShare
                    link={window.location.href}
                    loading={loading}
                />
                <OpinionComments
                    loading={loading}
                />
            </Flex>
        </WithSideBar>
    )

}