import { useQueryAccount } from "api/hook/user";
import { useTranslation } from "react-i18next";
import { Feed } from "ui";
import { WithSideBar } from "layout";
import { Button, Flex, Text } from "@mantine/core";
import { makeLink } from "utils";
import { useNavigate } from "react-router-dom";

export default function Voted() {

    const { t } = useTranslation();
    const {
        data,
        isLoading,
        failureCount,
        failureReason,
    } = useQueryAccount();

    const navigate = useNavigate();

    return !data ? (
        <WithSideBar>
            <Flex direction={'column'} align={'center'} justify={'center'} style={{ height: '100%' }} gap={'lg'}>
                <Text>{t('Please login to see your voted opinions')}</Text>
                <Button component="a" href={makeLink('/login')} 
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(makeLink('/login'));
                    }}
                >
                    {t('Sign In')}
                </Button>
            </Flex>
        </WithSideBar>
    ) : (
        <Feed args={{
            limit: 10,
            offset: 0,
            voted: true,
        }} />
    )

}