import { Anchor, Badge, Flex, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Category } from "types";
import { makeLink } from "utils";

export default function HashtagsRow({
    categories
}: {
    categories: Category[];
}) {

    const theme = useMantineTheme();
    const navigate = useNavigate();

    return (
        <Flex direction={'row'} gap={'xs'} justify={'center'}>
            {
                categories.map((item, index) => (
                    <Anchor key={index} onClick={() => navigate(makeLink(`/category/${item.id}`))} color={theme.colorScheme === 'dark' ? theme.colors.gray[3] : theme.colors.gray[7]}>
                        <Badge variant="filled" size={'lg'}>
                            {item.name}
                        </Badge>
                    </Anchor>
                ))
            }
        </Flex>
    )
}