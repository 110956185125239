import { apiWithInterceptor } from './';
import axios, { CancelTokenSource } from 'axios';

let cancelTokens: { [key: string]: CancelTokenSource } = {};

export const getCategory = async (id: number): Promise<any> => {
    cancelTokens['getCategory' + id] && cancelTokens['getCategory' + id].cancel();
    cancelTokens['getCategory' + id] = axios.CancelToken.source();
    return apiWithInterceptor.get(`/categories/${id}`)
};
