import { Avatar, Card, Flex, Text, Progress, Skeleton, Box, Button, Image, Badge } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconEditCircle, IconStars } from "@tabler/icons";
import { updateAccountPicture } from "api/fetch/account";
import { useQueryAccount } from "api/hook/user";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import parseError from "utils/parseError";
import ProfileModal from "./ProfileModal";


export default function UserCard() {

    const { t } = useTranslation();
    const profilePictureRef = useRef<HTMLInputElement>(null);
    const [hoverAvatar, setHoverAvatar] = useState<boolean>(false);

    const {
        data,
        isLoading,
        failureCount,
        failureReason,
        refetch,
    } = useQueryAccount();

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    if (failureCount) {

        const { code, msg } = parseError(failureReason);

        if (loading || code == "rest_unauthorized") {
            return (
                <></>
            );
        }

        return (
            <Card withBorder>
                <Flex direction={'column'} gap={5} align={'center'}>
                    <Text size={'md'} weight={500} >
                        {msg}
                    </Text>
                </Flex>
            </Card>
        )

    }

    return (
        <Card withBorder>
            <Flex direction={'column'} gap={5} align={'center'}>
                {
                    loading ? (
                        <Skeleton width={65} height={65} radius={100} />
                    ) : (
                        <Flex
                            sx={{
                                position: 'relative',
                            }}
                        >
                            <input type="file" accept="image/*" style={{ display: 'none' }} ref={profilePictureRef} id="picture" onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                    const file = e.target.files[0];
                                    setLoading(true);
                                    setHoverAvatar(false);
                                    updateAccountPicture({
                                        picture: file,
                                    }).then(() => {
                                        refetch();
                                    }).catch((err) => {
                                        alert(parseError(err).msg);
                                    }).finally(() => {
                                        setLoading(false);
                                    });
                                }
                            }} />
                            <Avatar
                                src={data?.profile_picture?.sizes?.thumbnail}
                                size={80}
                                radius={999}
                                color="blue"
                                onMouseEnter={() => setHoverAvatar(true)}
                                onMouseLeave={() => setHoverAvatar(false)}
                            >
                                {data?.name[0]}
                            </Avatar>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: 999,
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                    opacity: hoverAvatar ? 1 : 0,
                                    transition: 'opacity 0.3s ease',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={() => setHoverAvatar(true)}
                                onMouseLeave={() => setHoverAvatar(false)}
                                onClick={() => {
                                    if (profilePictureRef.current) {
                                        profilePictureRef.current.click();
                                    }
                                }}
                            >
                                <IconEditCircle size={30} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} color="white" />
                            </Box>
                        </Flex>
                    )
                }
                <Flex direction={'column'} gap={5} align={'center'} w={'100%'}>
                    <Flex direction={'row'} gap={5} align={'center'} w={'100%'} justify={'center'}>
                        <Text size={'md'} weight={500} >
                            {
                                loading ? (
                                    <Skeleton width={100} height={15} my={6} />
                                ) : data?.name
                            }
                        </Text>
                    </Flex>
                    {
                        loading ? (
                            <Skeleton width={100} height={4} />
                        ) : <Progress value={100} size="sm" radius="xl" w={'100%'} style={{ maxWidth: 100 }} />
                    }
                </Flex>
                <Text size={'sm'} fw={400} color={'gray.6'}>
                    {
                        loading ? (
                            <Skeleton width={150} height={15} my={6} />
                        ) : (
                            data?.email
                        )
                    }
                </Text>
                <Flex direction={'row'} gap={5}>
                    {data?.badge.icon ? <Image src={data?.badge.icon} width={20} height={20} /> : <IconStars size={20} />}
                    <Text fw={500} size={'sm'}>
                        {
                            loading ? (
                                <Skeleton width={20} height={15} my={6} mx={5} />
                            ) : data && data?.badge.stars ? (Number(data?.badge.stars)).toLocaleString() : 0
                        }
                    </Text>
                    <Text fw={500} size={'sm'}>
                        {t('Contribute')}
                    </Text>
                    <Badge color="blue" variant="filled">
                        {data?.badge.name}
                    </Badge>
                </Flex>
                <Button
                    mt={5}
                    size="xs"
                    variant="outline"
                    onClick={() => {
                        modals.open({
                            title: t('Edit Profile'),
                            centered: true,
                            overlayProps: {
                                blur: 2,
                                color: 'gray',
                            },
                            sx: {
                                '& section': {
                                    overflow: 'visible!important',
                                }
                            },
                            children: (
                                <ProfileModal />
                            ),
                        });
                    }}
                >
                    {t('Edit Profile')}
                </Button>
            </Flex>
        </Card>
    )

}