import { Alert, Box, Button, Flex, LoadingOverlay, PasswordInput, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useLocalStorage, useViewportSize } from '@mantine/hooks';
import { GoogleLogin } from '@react-oauth/google';
import { IconAlertCircle, IconArrowBackUp, IconAsterisk, IconAt } from '@tabler/icons';
import { getToken, login, loginGoogle } from 'api/fetch/auth';
import { useQueryAccount } from 'api/hook/user';
import { useLinkLocale } from 'hooks';
import { CenterFocus, WarperLoginForm } from 'layout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { makeLink } from 'utils';
import parseError from 'utils/parseError';

export default function Login() {

    const { t } = useTranslation();
    const { width } = useViewportSize();
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [token, setToken] = useLocalStorage({
        key: 'token',
        defaultValue: '',
    });

    const [btnSize, setBtnSize] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xl'>('sm');

    useEffect(() => {
        if (width < 350) {
            setBtnSize('xs');
        } else {
            setBtnSize('sm');
        }
    }, [width]);

    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },
        validate: {
            email: (value) => (value.length < 5 || !value.match(/^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? t("Invalid email address") : null),
            password: (value) => (value.length < 8 ? t("Invalid password") : null),
        }
    });

    const targetLoacaleCode = useLinkLocale();
    // useEffect(() => {
    //     // remove old script
    //     const oldScript = document.getElementById('google-signin-client');
    //     if (oldScript) {
    //         oldScript.remove();
    //     }

    //     // <script src="https://accounts.google.com/gsi/client?hl=<locale_code>>"></script>
    //     const script = document.createElement('script');
    //     script.src = `https://accounts.google.com/gsi/client?hl=${targetLoacaleCode !== 'krd' ? targetLoacaleCode : 'en'}`;
    //     script.async = true;
    //     script.defer = true;
    //     script.id = 'google-signin-client';
    //     document.body.appendChild(script);

    // }, [targetLoacaleCode]);

    // useEffect(() => {
    //     if (token !== "") {
    //         setLoading(false);
    //         navigate('/app', { replace: true });
    //     }
    // }, [token]);

    const {
        data,
        isLoading,
        failureCount,
        failureReason,
        refetch,
    } = useQueryAccount();

    if (failureCount) {

        const { code, msg } = parseError(failureReason);

        if (!isLoading && code != "rest_unauthorized") {
            setLoading(false);
            navigate('/app', { replace: true });
        }

    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tokenId = params.get('tokenId');
        if (tokenId) {
            setLoading(true);
            getToken({
                tokenId,
            }).then((response) => {
                setToken(response.token);
            }).catch((error) => {
                const { msg } = parseError(error);
                setErrorMessage(msg);
                setLoading(false);
            });
        }
    }, []);

    return (
        <CenterFocus>
            <WarperLoginForm>
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Flex
                    direction="column"
                    gap="xs"
                    style={{
                        maxWidth: 300,
                        margin: '0 auto',
                        width: '100%',
                    }}
                >
                    {
                        errorMessage !== "" && (
                            <Alert icon={<IconAlertCircle size="1rem" />} title="Error!" color="red" radius="sm">
                                {errorMessage}
                            </Alert>
                        )
                    }
                    <Box
                        sx={{
                            borderRadius: 5,
                            overflow: 'hidden',
                            width: 'fit-content',
                            backgroundColor: theme.white,
                        }}
                    >
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                const token = credentialResponse.credential;
                                if (!token) {
                                    alert(t('حدث خطأ ما أثناء تسجيل الدخول بحساب جوجل'));
                                    return;
                                }
                                setLoading(true)
                                loginGoogle({ token }).then((res: any) => {
                                    localStorage.setItem('token', res.token)
                                    navigate('/app')
                                }).catch((err: any) => {
                                    // notifications.show({
                                    //     title: t('خطأ'),
                                    //     message: err.message,
                                    //     color: 'red',
                                    // })
                                    alert(err.message)
                                }).finally(() => {
                                    setLoading(false)
                                });
                            }}
                            onError={() => {
                                alert(t('حدث خطأ ما أثناء تسجيل الدخول بحساب جوجل'));
                            }}
                            shape="rectangular"
                            text="continue_with"
                            // theme="filled_blue"
                            locale={targetLoacaleCode !== 'ku' ? targetLoacaleCode : 'en'}
                            type="standard"
                            size='large'
                            auto_select={true}
                            useOneTap={true}
                        />
                    </Box>

                    <form
                        onSubmit={form.onSubmit(values => {
                            setErrorMessage("");
                            setLoading(true);

                            login({
                                email: values.email,
                                password: values.password,
                            }).then((res) => {
                                setToken(res.token);
                            }).catch((err) => {
                                const { msg } = parseError(err);
                                setErrorMessage(msg);
                                form.setErrors({ email: true, password: true });
                            }).finally(() => {
                                setLoading(false);
                            });
                        })}
                    >
                        <Flex
                            direction="column"
                            gap="xs"
                            pb={'lg'}
                            style={{
                                maxWidth: 300,
                                margin: '0 auto',
                            }}
                        >
                            <TextInput
                                placeholder={t('Email') as string}
                                label={t('Email') as string}
                                variant="filled"
                                size="sm"
                                icon={<IconAt size={20} />}
                                {...form.getInputProps('email')}
                            />
                            <PasswordInput
                                placeholder={t('Password') as string}
                                label={t('Password') as string}
                                variant="filled"
                                size="sm"
                                icon={<IconAsterisk size={20} />}
                                {...form.getInputProps('password')}
                            />
                            {/* <Link to={makeLink('/login/forgot')}>
                                <Text align="right" size="xs">
                                    {t('Forgot password?')}
                                </Text>
                            </Link> */}
                            <Button
                                variant="gradient"
                                type="submit"
                                size={btnSize}
                                mt={'sx'}
                            >
                                {t('Sign In')}
                            </Button>
                            {/* <Text align="center" size="xs">
                                {t('Or')}
                            </Text>
                            <Button
                                variant="white"
                                size={btnSize}
                                onClick={() => navigate(makeLink('/register'))}
                            >
                                {t('Create Account')}
                            </Button> */}
                        </Flex>
                    </form>
                    {/* {
                        [
                            {
                                label: "Login with Email",
                                icon: <img src="https://img.icons8.com/color/48/000000/email.png" alt="Email" height={30} />,
                                onClick: () => {
                                    navigate(makeLink('/login/email'));
                                },
                            },
                        ].map((item, index) => (
                            <Button
                                variant="light"
                                bg={'gray.8'}
                                color={'gray.1'}
                                key={index}
                                px={5}
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                }}
                                onClick={item.onClick ? item.onClick : () => { }}
                                radius={'sm'}
                            >
                                {item.icon}
                                <Text ml={5} size={width > 320 ? 'sm' : 'xs'}>
                                    {t(item.label)}
                                </Text>
                            </Button>
                        ))
                    } */}
                </Flex>
            </WarperLoginForm>
        </CenterFocus>
    );

}