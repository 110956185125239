import { Button, Card, Flex, Select, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconUser } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { DateInput } from "@mantine/dates";

export default function UserLoginInformation() {

    const { t } = useTranslation();
    const form = useForm({
        initialValues: {
            username: '',
            email: '',
            phone: '',
        }
    });

    return (
        <Card withBorder sx={{ overflow: 'visible' }}>
            <Flex direction={'column'} gap={'xs'} align={'center'} justify={'center'}>
                <IconUser size={50} />
                <Text weight={700} size={'sm'}>
                    {t('Personal Information')}
                </Text>
                <form style={{ width: '100%' }}>
                    <Flex direction={'column'} gap={'xs'}>
                        {
                            [
                                {
                                    name: 'gender',
                                    label: t('Gender'),
                                    type: 'select',
                                    props: {
                                        data: [{ label: t("Male"), value: 'male' }, { label: t("Female"), value: 'female' }]
                                    }
                                },
                                {
                                    name: 'birthday',
                                    label: t('Birthday'),
                                    type: 'date',
                                },
                                {
                                    name: 'education',
                                    label: t('Education'),
                                    type: 'select',
                                    props: {
                                        data: [
                                            { label: t("None"), value: 'none' },
                                            { label: t("Primary School"), value: 'primary_school' },
                                            { label: t("High School"), value: 'high_school' },
                                            { label: t("University"), value: 'university' },
                                            { label: t("Diploma"), value: 'diploma' },
                                            { label: t("Master"), value: 'master' },
                                            { label: t("Doctorate"), value: 'doctorate' },
                                        ],
                                        searchable: true,
                                    }
                                },
                            ].map(({ type, props, name, label }, index) => (
                                <>
                                    {
                                        type === 'text' ? (
                                            <TextInput
                                                label={label as string}
                                                key={index}
                                                placeholder={label as string}
                                                variant={'filled'}
                                                size={'xs'}
                                                {...props}
                                                {...form.getInputProps(name)}
                                            />
                                        ) : type === 'select' ? (
                                            <Select
                                                label={label as string}
                                                key={index}
                                                placeholder={label as string}
                                                variant={'filled'}
                                                size={'xs'}
                                                data={props?.data ? props.data || [] : []}
                                                {...props}
                                                {...form.getInputProps(name)}
                                            />
                                        ) : type === 'date' ? (
                                            <DateInput
                                                label={label as string}
                                                placeholder={label as string}
                                                variant={'filled'}
                                                size={'xs'}
                                                {...props}
                                                {...form.getInputProps(name)}
                                            />
                                        ) : null
                                    }
                                </>
                            ))
                        }
                        {
                            form.isDirty() && (
                                <Button
                                    variant={'white'}
                                    size="xs"
                                    fullWidth
                                >
                                    {t('Update')}
                                </Button>
                            )
                        }
                    </Flex>
                </form>
            </Flex>
        </Card>
    )

}