import { apiWithoutInterceptor } from './';
import { CancelTokenSource } from 'axios';

let cancelTokens: { [key: string]: CancelTokenSource } = {};

export const login = async (data: {
    email: string;
    password: string;
}) : Promise<any> => {
    return apiWithoutInterceptor.post("/auth/classic", data);
}

export const loginGoogle = async (data: {
    token: string;
}) : Promise<any> => {
    return apiWithoutInterceptor.post("/auth/google", data);
}

export const getToken = async ({
    tokenId,
}: {
    tokenId: string;
}): Promise<any> => {
    return apiWithoutInterceptor.post("/auth/token", {
        token_id: tokenId,
    });
}

export const getPasswordRestCode = async ( email: string ): Promise<any> => {
    return apiWithoutInterceptor.post("/auth/reset-password/code", {
        email,
    });
}

export const validatePasswordRestCode = async ( email: string, code: string ): Promise<any> => {
    return apiWithoutInterceptor.post("/auth/reset-password/check", {
        email,
        code,
    });
}

export const resetPassword = async ( email: string, code: string, password: string ): Promise<any> => {
    return apiWithoutInterceptor.post("/auth/reset-password", {
        email,
        code,
        password,
    });
}