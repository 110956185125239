import { Box, Flex, Loader, Text } from "@mantine/core";
import { WithSideBar } from "layout";
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { OpinionCard } from "ui";
import { useEffect } from "react";
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { IGetOpinions, getOpinions } from "api/fetch/opinions";
import { useTranslation } from "react-i18next";
import { Opinion } from "types";

export default function Feed({
    args,
    trending = false,
    children,
}: {
    args: IGetOpinions
    trending?: boolean
    children?: React.ReactNode
}) {


    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isInitialLoading,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ['opinions', trending, args.order, args.orderby, args.search, args.post_status, args.cat, args.after, args.before, args.voted, args.seed],
        queryFn: ({ pageParam = 0 }) => getOpinions({ limit: 10, ...args, offset: pageParam }, trending),
        getNextPageParam: (lastPage, pages) => {
            const limit = lastPage.limit;
            const offset = lastPage.offset + limit;
            const total = lastPage?.opinions?.length ?? 0;
            return total ? offset : undefined;
        },
        cacheTime: 1000 * 10,
        staleTime: Infinity,
    })

    const [posts, setPosts] = React.useState<any[]>([])
    useEffect(() => {
        if (data) {
            setPosts(data.pages.flat().map((page: any) => page.opinions).flat());
        }
    }, [data])

    const { t } = useTranslation();

    const parentRef = React.useRef<HTMLDivElement>(null)

    const parentOffsetRef = React.useRef(0)

    const virtualizer = useWindowVirtualizer({
        count: posts?.length ?? 0,
        estimateSize: () => 400,
        scrollMargin: parentOffsetRef.current,
    })
    const items = virtualizer.getVirtualItems()

    // on reach bottom of the page fetch next page
    useEffect(() => {
        if (items.length === 0) return;
        const lastItem = items[items.length - 1];
        if (lastItem.index === posts.length - 1) {
            fetchNextPage();
        }
    }, [items]);

    return (
        <WithSideBar>
            {children}
            <Box ref={parentRef} className="List">
                <Box
                    style={{
                        height: virtualizer.getTotalSize(),
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <Flex
                        direction={'column'}
                        gap={'xs'}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${(items.length ? items[0].start : 0) - virtualizer.options.scrollMargin}px)`,
                        }}
                    >
                        {
                            isInitialLoading ? (
                                Array.from({ length: 10 }).map((_, index) => (
                                    <OpinionCard loading={true} key={index} />
                                ))
                            ) : (
                                items.map((virtualRow) => (
                                    <Box
                                        key={virtualRow.key}
                                        data-index={virtualRow.index}
                                        ref={virtualizer.measureElement}
                                        className={virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
                                    >
                                        <OpinionCard opinion={posts[virtualRow.index] as Opinion} />
                                    </Box>
                                ))
                            )
                        }
                        {
                            hasNextPage && isFetchingNextPage && (
                                <Flex py={'lg'} justify={'center'}>
                                    <Loader />
                                </Flex>
                            )
                        }
                        {
                            !hasNextPage && (
                                <Box py={'lg'}>
                                    <Text align="center">{t('You have reached the end of the world!')}</Text>
                                </Box>
                            )
                        }
                    </Flex>
                </Box>
            </Box>
        </WithSideBar>
    )

}