import locales from 'locale/locales.json';
import { t } from 'i18next';

export default function parseError(axios: any) {
  
    let code = (axios as any)?.data?.code || "unknown_error";
    let msg = (axios as any)?.data?.message || t('unknown error occurred');

    code = (axios as any)?.response?.data?.code || code;
    msg = (axios as any)?.response?.data?.message || msg;

    return {
        code,
        msg,
    }
    
}