import { Button, CopyButton, Flex, Menu, Text, rem } from "@mantine/core";
import { IconBrandFacebook, IconBrandTwitter, IconBrandWhatsapp, IconChecks, IconEye, IconLink, IconMessageCircle, IconShare } from "@tabler/icons";
import useCurrentLang from "hooks/useCurrentLang";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Opinion } from "types";

export default function ActionsRow({
    opinion
}: {
    opinion?: Opinion;
}) {

    const width = window.innerWidth;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const lang = useCurrentLang();

    const link = `${window.location.origin}/opinion/${opinion?.id}`;

    return (
        <Flex direction={'row'} justify={'center'} align={'center'} >
            <Menu shadow="md">
                <Menu.Target>
                    <Button
                        variant={'subtle'}
                        size="xs"
                        px={width < 400 ? 5 : 'xs'}
                    >
                        <IconShare size={15} />
                        <Text size={'xs'} style={{ margin: '0 5px' }}>
                            {t('Ask a friend')}
                        </Text>
                    </Button>
                </Menu.Target>

                <Menu.Dropdown>
                    {
                        [
                            {
                                icon: <IconBrandFacebook size={20} />,
                                platform: 'facebook',
                            },
                            {
                                icon: <IconBrandTwitter size={20} />,
                                platform: 'twitter',
                            },
                            {
                                icon: <IconBrandWhatsapp size={20} />,
                                platform: 'whatsapp',
                            },
                        ].map(({ icon, platform }) => (
                            <Menu.Item
                                key={platform}
                                icon={icon}
                                onClick={() => {
                                    if (platform === 'facebook') {
                                        window.open(`https://www.facebook.com/sharer/sharer.php?u=${link}`);
                                    } else if (platform === 'twitter') {
                                        window.open(`https://twitter.com/intent/tweet?text=${link}`);
                                    } else if (platform === 'whatsapp') {
                                        window.open(`https://api.whatsapp.com/send?text=${link}`);
                                    }
                                }}
                            >
                                {t(platform)}
                            </Menu.Item>
                        ))
                    }
                </Menu.Dropdown>
            </Menu>
            {
                [
                    {
                        label: opinion?.comment_count,
                        icon: IconMessageCircle,
                    },
                    // {
                    //     label: opinion?.votes?.total,
                    //     icon: IconChecks,
                    // },
                    // {
                    //     label: opinion?.views,
                    //     icon: IconEye,
                    // }
                ].map((item, index) => (
                    <Button
                        key={index}
                        variant={'subtle'}
                        size="xs"
                        px={width < 400 ? 5 : 'xs'}
                        onClick={() => {
                            navigate(`${lang}/opinion/${opinion?.id}`);
                        }}
                    >
                        <item.icon size={15} />
                        <Text size={'xs'} style={{ margin: '0 5px' }}>
                            {isNaN(Number(item.label)) ? item.label : Number(item.label).toLocaleString()}
                        </Text>
                    </Button>
                ))
            }
        </Flex>
    )

}