import locales from 'locale/locales.json';

export default function makeLink(link: string) {
  
    const codes = locales.map((locale) => locale.code);
    const defaultLocale = locales.find((locale) => locale.default)?.code;
    
    let locale = ''
    
    const currentLocale = window.location.pathname.split('/')[1];
    if (currentLocale && codes.includes(currentLocale) && currentLocale !== defaultLocale) {
        locale = currentLocale;
        return `/${locale}${link}`;
    }

    return link;
    
}