import { Anchor, Card, Flex, Title, useMantineTheme, Button, Box, Skeleton, Center } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ActionsRow, HashtagsRow, VoteRow } from "components";
import { Opinion } from "types";
import { unvote, vote } from "api/fetch/opinions";
import { makeLink } from "utils";
import LogoWhite from 'assets/logo-white.svg';
import LogoBlack from 'assets/logo-black.svg';
import useCurrentLang from "hooks/useCurrentLang";


export default function OpinionCard({
    opinion,
    loading = false,
}: {
    opinion?: Opinion,
    loading?: boolean,
}) {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [opinionData, setOpinionData] = useState<Opinion | null>(opinion || null);
    useEffect(() => setOpinionData(opinion as Opinion | null), [opinion]);

    const [voting, setVoting] = useState<boolean>(false);
    const lang = useCurrentLang();

    const image = useMemo(() => {
        let url = null;
        if (opinionData?.featured_image) {
            if (opinionData?.featured_image?.sizes?.large) {
                url = opinionData?.featured_image?.sizes?.large;
            } else if (opinionData?.featured_image?.sizes?.medium) {
                url = opinionData?.featured_image?.sizes?.medium;
            } else if (opinionData?.featured_image?.sizes?.full) {
                url = opinionData?.featured_image?.sizes?.full;
            }
        }
        return url;
    }, [opinionData]);

    const bg = useMemo(() => {
        return theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white;
    }, [theme.colorScheme]);

    return (
        <Card
            withBorder
            className={'feed-card'}
            px={0}
            pt={image != null ? 0 : 'xs'}
            pb={image != null ? 100 : 'xs'}
            bg={bg}
            sx={{
                position: 'relative',
                maxHeight: 'calc(100vh - 200px)',
            }}
        >
            <Box>
                {
                    image != null && !loading && (
                        <Box
                            sx={{
                                width: '100%',
                                aspectRatio: '1/1',
                                backgroundImage: `linear-gradient(0deg, ${bg} 0%, transparent 100%), url(${image})`,
                                backgroundSize: 'cover, cover',
                                backgroundPosition: 'center, center',
                                backgroundRepeat: 'no-repeat, no-repeat',
                            }}
                        />
                    )
                }
                {
                    loading && (
                        <Box
                            sx={{
                                width: '100%',
                                aspectRatio: '1/0.85',
                                backgroundImage: `linear-gradient(0deg, ${bg} 0%, transparent 100%), url(${theme.colorScheme === 'dark' ? LogoWhite : LogoBlack})`,
                                backgroundSize: 'cover, 30% 30%',
                                backgroundPosition: 'center, center',
                                backgroundRepeat: 'no-repeat, no-repeat',
                            }}
                        />
                    )
                }
            </Box>
            <Flex direction={'column'} gap={'xs'} px={5} py={10}
                sx={image != null ? {
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    backgroundImage: `linear-gradient(0deg, ${bg} 0%, transparent 100%)`,
                } : {

                }}
            >

                {opinionData?.categories && <HashtagsRow categories={opinionData.categories} />}

                <Anchor component={Link} to={makeLink(`/${lang}/opinion/${opinionData?.id}`)} color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9]}>
                    <Title order={1} style={{ textAlign: 'center', color: theme.colorScheme === 'dark' ? theme.white : theme.black }} px={'md'} weight={700} size={22}>
                        {
                            loading ? (
                                <Center>
                                    <Skeleton height={20} width={'50%'} radius={'md'} />
                                </Center>
                            ) : (
                                opinionData?.title.replace(/\./g, '')
                            )
                        }
                    </Title>
                </Anchor>

                {opinionData?.user_vote == null ? (
                    <Button size={'xs'} color={'gray.6'} variant="transparent">
                        {
                            voting || loading ? (
                                <Skeleton height={15} width={100} radius={'md'} />
                            ) : (
                                t("Not sure? ask a friend")
                            )
                        }
                    </Button>
                ) : (
                    <Button size={'xs'} color={'gray.6'} variant="transparent"
                        onClick={() => {
                            setVoting(true);
                            unvote(opinionData.id).then((data) => {
                                setOpinionData(data);
                            }).catch((error) => {

                            }).finally(() => {
                                setVoting(false);
                            });
                        }}
                    >
                        {
                            voting || loading ? (
                                <Skeleton height={15} width={100} radius={'md'} />
                            ) : (
                                t("Changed your mind?")
                            )
                        }
                    </Button>
                )}

                {
                    !loading && opinionData ? (
                        <VoteRow
                            opinion={opinionData as Opinion}
                            onVote={
                                (voteType) => {
                                    if (opinionData?.user_vote == null) {
                                        setVoting(true);
                                        vote(opinionData?.id, voteType).then((data) => {
                                            setOpinionData(data);
                                        }).catch((error) => {

                                        }).finally(() => {
                                            setVoting(false);
                                        });
                                    }
                                }}
                            loading={voting}
                        />
                    ) : (
                        <Flex direction={'row'} gap={3}>
                            <Skeleton height={40} width={'100%'} radius={0} />
                            <Skeleton height={40} width={'100%'} radius={0} />
                            <Skeleton height={40} width={'100%'} radius={0} />
                            <Skeleton height={40} width={'100%'} radius={0} />
                            <Skeleton height={40} width={'100%'} radius={0} />
                        </Flex>
                    )
                }

                {
                    !loading && opinionData ? (
                        <ActionsRow opinion={opinionData as Opinion} />
                    ) : (
                        <Flex direction={'row'} gap={'xs'} justify={'center'}>
                            <Skeleton height={20} width={40} radius={'md'} />
                            <Skeleton height={20} width={40} radius={'md'} />
                            <Skeleton height={20} width={40} radius={'md'} />
                        </Flex>
                    )
                }
            </Flex>
        </Card>
    )

}