export default {
    defaultRadius: 10,
    colors: {
        brand: [
            "#fef2f5",
            "#eae6e7",
            "#cdcdcd",
            "#b2b2b2",
            "#9a9a9a",
            "#8b8b8b",
            "#848484",
            "#717171",
            "#676465",
            "#5e5457"
          ],
        vote: ['#E80C14', '#FF6060', '#FCD903', '#579BFC', '#0753D9'],
        voteInverse: ['#FFFFFF', '#FFFFFF', '#000000', '#FFFFFF', '#FFFFFF'],
    },
    // primaryColor: 'indigo',
    primaryColor: 'brand',
    colorScheme: 'light',
    headings: {
        // fontFamily: 'Vazirmatn',
        // fontFamily: CSSProperties['fontFamily'];
        // fontWeight: CSSProperties['fontWeight'];
        sizes: {
            h1: {
                fontSize: '1.3rem',
            },
            h2: {
                fontSize: '1.1rem',
            },
            h3: {
                fontSize: '1rem',
            },
            h4: {
                fontSize: '0.9rem',
            },
            h5: {
                fontSize: '0.9rem',
            },
            h6: {
                fontSize: '0.9rem',
            },
        }
    },
    dir: 'rtl',
};