import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import arabicLocale from "./ar.json";
import englishLocale from "./en.json";
import kurdishLocale from "./krd.json";

import locales from './locales';

const defaultLocale = locales.find(locale => locale.default).code;
const otherLocales = locales.filter(locale => !locale.default).map(locale => locale.code);

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: englishLocale
    },
    ar: {
        translation: arabicLocale
    },
    ku: {
        translation: kurdishLocale
    },
    krd: {
        translation: kurdishLocale
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        defaultLanguage: defaultLocale,
        otherLanguages: otherLocales,
        lng: defaultLocale, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;