import { apiWithInterceptor } from './';
import axios, { CancelTokenSource } from 'axios';
import { Opinion as OpinionType } from '../../types';

let cancelTokens: { [key: string]: CancelTokenSource } = {};

export interface IGetComments {
    offset?: number,
    limit?: number,
    parent?: number,
    show_children?: boolean,
}

export const getComments = async (opinion_id: number, data: IGetComments = {
    offset: 0,
    limit: 999,
    show_children: true
}): Promise<any> => {
    cancelTokens.getOpinions && cancelTokens.getOpinions.cancel();
    cancelTokens.getOpinions = axios.CancelToken.source();
    return apiWithInterceptor.get(`/comments/${opinion_id}`, {
        params: data,
    })
};

export const likeComment = async (comment_id: number): Promise<any> => {
    cancelTokens.likeComment && cancelTokens.likeComment.cancel();
    cancelTokens.likeComment = axios.CancelToken.source();
    return apiWithInterceptor.post(`/comments/${comment_id}/like`, {}, {
        cancelToken: cancelTokens.likeComment.token
    })
}

export const unlikeComment = async (comment_id: number): Promise<any> => {
    cancelTokens.unlikeComment && cancelTokens.unlikeComment.cancel();
    cancelTokens.unlikeComment = axios.CancelToken.source();
    return apiWithInterceptor.delete(`/comments/${comment_id}/like`, {
        cancelToken: cancelTokens.unlikeComment.token
    })
}

export const createComment = async (opinion_id: number, data: {
    parent?: number,
    type?: "with" | "against" | "commentary",
    content: string,
}): Promise<any> => {
    cancelTokens.createComment && cancelTokens.createComment.cancel();
    cancelTokens.createComment = axios.CancelToken.source();
    let { parent, type, content } = data;
    let newData = {};
    if (parent) newData = { ...newData, parent };
    if (type) newData = { ...newData, type };
    if (content) newData = { ...newData, content };
    return apiWithInterceptor.post(`/comments/${opinion_id}`, newData, {
        cancelToken: cancelTokens.createComment.token
    })
}

export const deleteComment = async (comment_id: number): Promise<any> => {
    cancelTokens.deleteComment && cancelTokens.deleteComment.cancel();
    cancelTokens.deleteComment = axios.CancelToken.source();
    return apiWithInterceptor.delete(`/comments/${comment_id}`, {
        cancelToken: cancelTokens.deleteComment.token
    })
}

export const editComment = async (comment_id: number, data: {
    content: string,
    type?: "with" | "against" | "commentary",
}): Promise<any> => {
    cancelTokens.editComment && cancelTokens.editComment.cancel();
    cancelTokens.editComment = axios.CancelToken.source();
    let { content } = data;
    let newData = {};
    if (content) newData = { ...newData, content };
    if (data.type) newData = { ...newData, type: data.type };
    return apiWithInterceptor.patch(`/comments/${comment_id}`, newData, {
        cancelToken: cancelTokens.editComment.token
    })
}