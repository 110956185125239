import axios from 'axios'

export const Domain = {
    production: "https://curiosity.qarar.me/rest/qarar-app/v2/",
    development: "http://site-qararapi.local/wp-json/qarar-app/v2/",
}

const apiWithoutInterceptor = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? Domain.production
        : Domain.development
        }`,
    headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
    },
});

const apiWithInterceptor = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? Domain.production
        : Domain.development
        }`,
    headers: {
        "Content-Type": "application/json",
        // "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
    },
});

const apiWithInterceptorFormData = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? Domain.production
        : Domain.development
        }`,
    headers: {
        "Content-Type": "multipart/form-data",
    },
});

[apiWithInterceptor, apiWithoutInterceptor, apiWithInterceptorFormData].forEach((api) => {
    api.interceptors.request.use(async (config) => {
        let locale = localStorage.getItem("locale") || "en";
        // remove double quotes from locale
        locale = locale.replace(/['"]+/g, "");
        locale = locale === "ku" ? "krd" : locale;
        const path = config.url?.replace(Domain.production, "").replace(Domain.development, "");
        config.url = process.env.NODE_ENV === "production" ? `${Domain.production}${locale}${path}` : `${Domain.development}${locale}${path}`;
        return config;
    });
});

apiWithInterceptor.interceptors.request.use(async (config) => {
    let token = localStorage?.getItem("token")?.replace(/['"]+/g, "");

    if (token) {
        if (!config.headers) config.headers = {} as any;
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

apiWithInterceptorFormData.interceptors.request.use(async (config) => {
    let token = localStorage?.getItem("token")?.replace(/['"]+/g, "");

    if (token) {
        if (!config.headers) config.headers = {} as any;
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// filter all responses and check if the status is 200 and the key success is true then return the data
[apiWithInterceptor, apiWithoutInterceptor, apiWithInterceptorFormData].forEach((api) => {
    api.interceptors.response.use((response) => {
        if (response.status === 200 && response.data.code === "rest_ok") {
            return response.data.data;
        }
        return response;
    }, (error) => {
        return Promise.reject(error);
    });
});

export {
    apiWithInterceptor,
    apiWithoutInterceptor,
    apiWithInterceptorFormData,
};