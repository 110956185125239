import { Flex, Grid } from "@mantine/core";
import { NoSideBar } from "layout";
import { FooterBox, UserCard, UserLoginInformation, UserLogout, UserPersonalInformation } from "ui";

export default function Profile() {

    return (
        <NoSideBar>
            <Grid pt={'md'}>
                <Grid.Col span={12} sm={4}>
                    <Flex direction={'column'} gap={'xs'}>
                        <UserCard />
                        <UserLogout />
                        <FooterBox />
                    </Flex>
                </Grid.Col>
                <Grid.Col span={12} sm={4}>
                    <UserLoginInformation />
                </Grid.Col>
                <Grid.Col span={12} sm={4}>
                    <UserPersonalInformation />
                </Grid.Col>
            </Grid>
        </NoSideBar>
    )

}