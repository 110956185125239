import { ActionIcon, Box, Button, Card, CopyButton, Flex, Skeleton, Text, useMantineTheme } from "@mantine/core";
import { IconBrandFacebook, IconBrandTwitter, IconBrandWhatsapp } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function OpinionShare({
    link,
    loading = false,
}: {
    link: string,
    loading?: boolean,
}) {

    const theme = useMantineTheme();
    const { t , i18n } = useTranslation();

    // split link
    const [shareLink, setLink] = useState<string>(link);
    useEffect(() => {

        // split link 
        // let newLink = link.split('?')[0];
        // // remove trailing slash
        // if (newLink.endsWith('/')) {
        //     newLink = newLink.slice(0, -1);
        // }
        // // split link with / and add current language after the domain
        // const splitLink = newLink.split('/');
        // newLink = `${splitLink[0]}//${splitLink[2]}/${i18n.language}${splitLink.slice(3).join('/')}`;

        setLink(window.location.href);
    }, [window.location.href]);

    return (
        <Card withBorder>
            <Flex direction={'column'} gap={'xs'}>
                <Text size={'sm'} color={theme.colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[7]}>
                    {
                        loading ? (
                            <Skeleton height={20} width={'30%'} radius={'md'} />
                        ) : (
                            t('Share with your friends')
                        )
                    }
                </Text>
                <Box
                    w={'100%'}
                    bg={theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}
                    p={'xs'}
                    sx={theme => ({
                        borderRadius: theme.radius.md,
                        position: 'relative',
                        overflow: 'hidden',
                    })}
                >
                    {
                        loading ? (
                            <Skeleton height={25} width={'75%'} radius={'md'} />
                        ) : (
                            <Text>
                                {shareLink}
                            </Text>
                        )
                    }
                    <CopyButton
                        value={shareLink}
                    >
                        {({ copied, copy }) => (
                            <Button
                                variant="white"
                                radius={'md'}
                                size={'xs'}
                                sx={{
                                    position: 'absolute',
                                    top: 7,
                                    right: 7
                                }}
                                onClick={copy}
                                disabled={loading}
                            >
                                {(copied ? t('Copied') : t('Copy')).toUpperCase()}
                            </Button>
                        )}
                    </CopyButton>
                </Box>
                <Flex gap={5}>
                    {
                        [
                            {
                                icon: <IconBrandFacebook size={20} />,
                                platform: 'facebook',
                            },
                            {
                                icon: <IconBrandTwitter size={20} />,
                                platform: 'twitter',
                            },
                            {
                                icon: <IconBrandWhatsapp size={20} />,
                                platform: 'whatsapp',
                            },
                        ].map(({ icon, platform }) => (
                            loading ? (
                                <Skeleton key={platform} height={35} width={35} radius={'md'} />
                            ) : (
                                <ActionIcon
                                    key={platform}
                                    variant={'default'}
                                    size={'lg'}
                                    onClick={() => {
                                        if (platform === 'facebook') {
                                            window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`);
                                        } else if (platform === 'twitter') {
                                            window.open(`https://twitter.com/intent/tweet?text=${shareLink}`);
                                        } else if (platform === 'whatsapp') {
                                            window.open(`https://api.whatsapp.com/send?text=${shareLink}`);
                                        }
                                    }}
                                >
                                    {icon}
                                </ActionIcon >
                            )
                        ))
                    }
                </Flex>
            </Flex>
        </Card>
    )

}