import {
    useQuery,
} from '@tanstack/react-query'

import {
    getAccount,
} from 'api/fetch/account';

export const useQueryAccount = () => useQuery(
    ['account'], 
    getAccount,
    {
        staleTime: 1000 * 60,
        cacheTime: 1000 * 60,
        retry: false,
    }
);