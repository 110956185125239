// vendors
import * as React from 'react'
import { useEffect } from 'react';
import { Button, createEmotionCache, Flex, MantineProvider } from '@mantine/core';
import { useLocalStorage, useSetState } from '@mantine/hooks';
import rtlPlugin from 'stylis-plugin-rtl';
import { useTranslation } from 'react-i18next';
import { modals, ModalsProvider } from '@mantine/modals';
import { HelmetProvider } from 'react-helmet-async';

// utils
import Router from './router';
import 'assets/App.css';
import { default as localesList } from 'locale/locales.json';
import { default as themeObj } from 'data/theme.js';
import { makeLink } from 'utils';
import { useLinkLocale } from 'hooks';
import { GoogleOAuthProvider } from '@react-oauth/google';


const rtlCache = createEmotionCache({
    key: 'mantine-rtl',
    stylisPlugins: [rtlPlugin],
});



function App() {

    const defaultTheme = React.useMemo(() => (themeObj), []);
    const [theme, setTheme] = useSetState(defaultTheme);

    const [colorScheme] = useLocalStorage({ key: 'color-scheme', defaultValue: 'dark' });
    useEffect(() => {
        setTheme({ colorScheme: colorScheme });
    }, [colorScheme]);

    const init = localStorage.getItem('init');
    if (!init) {
        modals.open({
            title: 'Language | اللغة',
            children: (
                <Flex direction={'column'} gap={'xs'}>
                    {
                        localesList.map((locale, index) => (
                            <Button
                                key={index}
                                variant='gradient'
                                onClick={() => {
                                    window.location.href = makeLink(`/${locale.code}`);
                                    localStorage.setItem('init', 'true');
                                }}
                            >
                                {locale.name}
                            </Button>
                        ))
                    }
                </Flex>
            ),
            centered: true,
            overlayProps: {
                color: colorScheme === 'dark' ? '#111' : '#444',
                opacity: 0.55,
                blur: 3,
            }
        });
    }

    const targetLoacaleCode = useLinkLocale();
    const { i18n } = useTranslation();

    useEffect(() => {
        localStorage.setItem('locale', targetLoacaleCode);
        i18n.changeLanguage(targetLoacaleCode);
    }, [targetLoacaleCode]);

    const currentLocaleObj = localesList.find(locale => locale.code === targetLoacaleCode);

    useEffect(() => {

        if (currentLocaleObj?.rtl) {
            setTheme({ dir: 'rtl' });
            document.dir = 'rtl'
            document.documentElement.lang = targetLoacaleCode;
        } else {
            setTheme({ dir: 'ltr' });
            document.dir = 'ltr'
            document.documentElement.lang = targetLoacaleCode;
        }

    }, [currentLocaleObj?.rtl]);

    return (
        <HelmetProvider>
            <MantineProvider
                theme={theme as object}
                withGlobalStyles
                withNormalizeCSS
                emotionCache={currentLocaleObj?.rtl ? rtlCache : undefined}
            >
                <ModalsProvider>
                    <GoogleOAuthProvider
                        clientId="1027951851732-7homs21qhrfskn7m7nst8h9um4q0otj4.apps.googleusercontent.com"
                    >
                        <Router />
                    </GoogleOAuthProvider>
                </ModalsProvider>
            </MantineProvider>
        </HelmetProvider>
    );

}

export default App;
