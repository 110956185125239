import { Button, Group, Text } from "@mantine/core";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { makeLink, setCurrentLinkLocale } from "utils";
import locales from 'locale/locales.json';
import { useTranslation } from "react-i18next";

export default function FooterBox() {

    const { t, i18n } = useTranslation();

    const items = useMemo(() => [
        {
            title: t('About'),
            link: 'https://qarar.me/about-us/',
        },
        {
            title: t('Privacy Policy'),
            link: 'https://qarar.me/privacy-policy/',
        },
    ], []);

    return (
        <>
            <Group spacing={5}>
                {
                    locales.map((locale, index) => (
                        i18n.language !== locale.code &&
                        <a href={setCurrentLinkLocale(locale.code)} key={index}>
                            <Button color="gray" variant="gradient" size="xs">
                                <Text>{t(locale.name)}</Text>
                            </Button>
                        </a>
                    ))
                }
                {items.map((item, index) => (
                    <Link to={makeLink(item.link)} key={index} target="_blank">
                        <Button color="gray" variant="light" size="xs">
                            <Text>{item.title}</Text>
                        </Button>
                    </Link>
                ))}
            </Group>
            <Text mt={5} size="xs" color="gray.6">
                {t('All rights reserved') + ' © ' + new Date().getFullYear()}
            </Text>
        </>
    );

}