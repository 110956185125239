import { TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

export default function SearchBox() {

    const { t } = useTranslation();
    const form = useForm({
        initialValues: { search: '' },
        validate: {
            search: (value) => (value.length < 3 ? t("Search term is very short") : null),
        },
    });
    const navigate = useNavigate();


    return (
        <form
            onSubmit={form.onSubmit(values => {
                if (values.search.length) {
                    navigate(`/search/${values.search}`);
                }
            })}
        >
            <TextInput
                size="xs"
                placeholder={t("Search") as string}
                className='search-box'
                {...form.getInputProps('search')}
            />
        </form>
    );

}