import { apiWithInterceptor } from './';
import axios, { CancelTokenSource } from 'axios';
import { Opinion as OpinionType } from '../../types';

let cancelTokens: { [key: string]: CancelTokenSource } = {};

export interface IGetOpinions {
    offset?: number,
    limit?: number,
    order?: 'ASC' | 'DESC',
    orderby?: string,
    search?: string,
    post_status?: 'publish' | 'draft' | 'pending',
    cat?: string,
    after?: Date,
    before?: Date,
    voted?: boolean,
    seed?: number,
}

export const getOpinions = async (data: IGetOpinions, trending: boolean = false): Promise<any> => {
    cancelTokens.getOpinions && cancelTokens.getOpinions.cancel();
    cancelTokens.getOpinions = axios.CancelToken.source();
    return apiWithInterceptor.get(`/opinions${ trending ? '/trending' : ''}`, {
        params: Object.assign({
            offset: 0,
            limit: 10,
            order: 'DESC',
            orderby: 'date',
            post_status: 'publish',
        }, data),
    })
};

export const getOpinion = async (id: number): Promise<any> => {
    cancelTokens.getOpinion && cancelTokens.getOpinion.cancel();
    cancelTokens.getOpinion = axios.CancelToken.source();
    return apiWithInterceptor.get(`/opinions/${id}`)
};

export const vote = async (id: number, type: number): Promise<any> => {
    cancelTokens.vote && cancelTokens.vote.cancel();
    cancelTokens.vote = axios.CancelToken.source();
    return apiWithInterceptor.post(`/opinions/${id}/vote`, {
        vote: type,
        web: true
    }, {
        cancelToken: cancelTokens.vote.token
    })
}

export const unvote = async (id: number): Promise<any> => {
    cancelTokens.unvote && cancelTokens.unvote.cancel();
    cancelTokens.unvote = axios.CancelToken.source();
    return apiWithInterceptor.delete(`/opinions/${id}/vote`, {
        cancelToken: cancelTokens.unvote.token
    })
}

export const seen = async (id: number): Promise<any> => {
    cancelTokens.see && cancelTokens.see.cancel();
    cancelTokens.see = axios.CancelToken.source();
    return apiWithInterceptor.post(`/opinions/${id}/seen`, {}, {
        cancelToken: cancelTokens.see.token
    })
}