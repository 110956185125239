import { Card, Grid, Title } from "@mantine/core";
import { BrandLogo } from "components";
import { useTranslation } from "react-i18next";
import { FooterBox } from "ui";

export default function WarperLoginForm({
    children,
}: {
    children: React.ReactNode;
}) {

    const { t } = useTranslation();

    return (
        <Grid style={{ maxWidth: 500 }}>
            <Grid.Col span={12}>
                <BrandLogo width={100} />
            </Grid.Col>
            <Grid.Col span={12}>
                <Title order={1} size={'h3'}>{t('Sign In / Register')}</Title>
            </Grid.Col>
            <Grid.Col span={12}>
                <Card>
                    {children}
                </Card>
            </Grid.Col>
            <Grid.Col span={12}>
                <FooterBox />
            </Grid.Col>
        </Grid>
    );

}